import { AvailableRooms } from './AvailableRooms'
import {
  Availability,
  AvailabilityCriteria,
  AvailabilityRate,
} from 'src/core/Availability/domain/Availability.model'
import { FC, useEffect, useState } from 'react'
import { container } from 'src/core/Shared/_di'
import { ActionKind, useStaysSummary } from './useStaysSummary'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { StaysSummary } from './StaysSummary'
import { useCart } from 'src/ui/contexts/CartContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useDeleteObsoleteCart } from 'src/ui/views/AvailableRooms/useDeleteObsoleteCart'
import {
  SelectedMealplan,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useAsyncMutation, useSyncMutation } from 'src/ui/hooks/useMutation'
import { mapCouponParam } from 'src/ui/views/AvailableRooms/mapCouponParam'
import { useModal } from 'src/ui/hooks/useModal'
import { RateSelectorModal } from 'src/ui/views/AvailableRooms/RateSelectorModal'
import { useRouter } from 'next/navigation'
import { routes } from 'src/ui/navigation/routes'
import { useUser } from 'src/ui/contexts/UserContext'
import { useMealplanFiltersInUrl } from 'src/ui/views/AvailableRooms/useMealplanFiltersInUrl'
import { ReservationCriteria } from 'src/core/Reservation/domain/ReservationCriteria'
import { scroller } from 'src/ui/utils/scroller'
import { useRoomTypeFiltersInUrl } from './useRoomTypeFiltersInUrl'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { AvailableRoomsV2 } from 'src/ui/views/AvailableRooms/AvailableRoomsV2'
import { useRateSelectorV2 } from 'src/ui/contexts/RateSelectorContextV2'

interface Props {
  onModifyOccupancy: () => void
  onModifyDates: () => void
  onModifyCoupon: () => void
}

export const MultiRoomController: FC<Props> = ({
  onModifyOccupancy,
  onModifyDates,
  onModifyCoupon,
}) => {
  const [reservationInProgress, setReservationInProgress] = useState(false)
  const [isRoomBeingSelected, setIsRoomBeingSelected] = useState(false)
  const [state, dispatch] = useStaysSummary()
  const { getRawParams, navigate, queryUtils } = useApplicationRouter()
  const { cart } = useCart()
  const { currentStayPosition, staysSummary, selectedCurrency } = state
  const currencyCode = queryUtils.getCurrencyParam()
  const { showModal: showChangeRateModal, hideModal: hideChangeRateModal } =
    useModal(RateSelectorModal)
  const router = useRouter()
  const { setIsMyBarceloToggleSelected } = useRateSelectorV2()
  const { isFiltering: isFilteringMealplan } = useMealplanFiltersInUrl()
  const {
    isFiltering: isFilteringRoomType,
    setFilter: setRoomTypeFilter,
    startFilterAll: startFilterAllRoomTypes,
    removeAllRoomTypeFilters,
    selectedFilter: selectedRoomTypeFilter,
  } = useRoomTypeFiltersInUrl()
  const { availabilityCoupon, howCouponAppliesInAvailability } = useCoupons()

  const {
    availability,
    availabilityCriteria,
    error: availabilityError,
    isValidating: isAvailabilityValidating,
    onAvailabilityRef,
  } = useAvailability()

  useDeleteObsoleteCart(cart, availabilityCriteria)

  const updateCouponFromSelectedRates = (
    availability: Availability | undefined,
  ) => {
    if (isDefined(availability)) {
      dispatch({
        type: ActionKind.UPDATE_AVAILABILITY_ON_STAYS_SUMMARY,
        payload: {
          availability,
        },
      })
    }
  }

  if (isDefined(onAvailabilityRef)) {
    onAvailabilityRef.current = updateCouponFromSelectedRates
  }

  const error =
    isDefined(availabilityError) && !isAvailabilityValidating
      ? availabilityError
      : undefined
  const isAvailabilityLoading =
    isUndefined(availability) || isAvailabilityValidating
  const { token } = useUser()

  useEffect(() => {
    if (isUndefined(cart) && isUndefined(availabilityCriteria)) {
      return
    }

    if (isUndefined(availability)) {
      return
    }

    if (isDefined(cart) && isDefined(availabilityCriteria)) {
      const roomStay = queryUtils.getRawParam('roomStay')
      dispatch({
        type: ActionKind.LOAD_STAYS_FROM_CART,
        payload: {
          cart,
          availabilityCriteria,
          availabilityCoupon,
          availability,
          defaultStayPosition: isDefined(roomStay) ? parseInt(roomStay) : 0,
        },
      })

      if (hasToShowNewAvailableRoomsView) {
        setIsMyBarceloToggleSelected(cart.roomStays[0].rate.isMember)
      }
    } else if (isDefined(availabilityCriteria)) {
      dispatch({
        type: ActionKind.LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA,
        payload: { availabilityCriteria },
      })
    }
  }, [cart, availability, availabilityCoupon, availabilityCriteria, dispatch])

  const selectStay = (stay: number) => {
    setRoomBeingSelected()
    dispatch({
      type: ActionKind.SELECT_STAY,
      payload: {
        selectedStayPosition: stay,
      },
    })

    if (hasToShowNewAvailableRoomsView && isDefined(staysSummary[stay])) {
      setIsMyBarceloToggleSelected(
        staysSummary[stay].room?.rateIsMember ?? false,
      )
    }
  }

  const getRoomsThatCouponDoesNotApply = () => {
    return staysSummary
      .map(stay => {
        if (!stay.room?.couponAppliesInRate) {
          return stay.room?.name
        }
      })
      .filter(isDefined)
  }

  const setRoomBeingSelected = () => {
    scroller.toTop()
    setIsRoomBeingSelected(true)
    setTimeout(() => setIsRoomBeingSelected(false), 500)
  }

  const addRoomToStays = (
    roomId: string,
    roomName: string,
    mealplan: SelectedMealplan,
    rate: AvailabilityRate,
  ) => {
    const numberOfPendingRooms = staysSummary.reduce(
      (acc, stay) => (isDefined(stay.room) ? acc - 1 : acc),
      staysSummary.length,
    )
    const areMoreRoomsToSelect = numberOfPendingRooms > 1
    if (areMoreRoomsToSelect) {
      setRoomBeingSelected()
    }

    const params = getRawParams(['arrive', 'depart'])
    dispatch({
      type: ActionKind.SELECT_RATE,
      payload: {
        roomId,
        roomName,
        mealplan,
        rate,
        checkIn: params?.arrive as string,
        checkOut: params?.depart as string,
        availability,
      },
    })
  }

  const { performMutation: filterAvailabilityByRoomType } = useSyncMutation(
    'filterAvailabilityRoomsByRoomTypeMultiRoom',
    () => {
      return container.resolve('filterAvailabilityRoomsByRoomType')(
        availability?.stays[currentStayPosition],
        selectedRoomTypeFilter,
        removeAllRoomTypeFilters,
        selectedCurrency,
      )
    },
  )

  const createReservation = async (
    availabilityCriteria: AvailabilityCriteria,
    availability: Availability,
  ) => {
    try {
      const reservationCriteria =
        ReservationCriteria.fromPrimitivesAndAvailabilityCriteria(
          availability.hotel.id,
          queryUtils.getMarketCampaign(),
          staysSummary
            .map(stay => {
              if (isUndefined(stay.room)) {
                return
              }

              const { rateId, id: roomId } = stay.room
              const couponAppliesInRates = stay.room?.couponAppliesInRate
              const originalTotalPrice = {
                grossPrice: stay.room?.nonConvertedGrossPrice,
                netPrice: stay.room?.nonConvertedNetPrice,
              }

              return {
                roomId,
                rateId,
                ...mapCouponParam({
                  availabilityCoupon,
                  promotionalCouponApplies: couponAppliesInRates,
                }),
                ...(isDefined(originalTotalPrice) && { originalTotalPrice }),
              }
            })
            .filter(isDefined),
          availabilityCriteria,
        )
      const rooms = staysSummary.map(stay => {
        if (isUndefined(stay.room)) {
          return
        }

        return {
          roomName: stay.room.name,
          mealplan: stay.room.mealplan,
          rateId: stay.room.rateId,
        }
      })
      const couponParam = queryUtils.getCouponParam()
      const groupCoupon = queryUtils.getRawParam('groupcode')

      await container.resolve('createReservation')(
        reservationCriteria,
        token,
        currencyCode,
        availability,
        rooms,
        couponParam,
        groupCoupon,
      )

      await navigate.toExtrasFromAvailability()
    } catch (error) {
      throw error
    } finally {
      setReservationInProgress(false)
    }
  }

  const onReserve = async () => {
    router.prefetch(routes.extras)
    setReservationInProgress(true)

    const allRoomsSelected = staysSummary.every(stay => isDefined(stay.room))
    if (
      isUndefined(availability) ||
      !allRoomsSelected ||
      isUndefined(availabilityCriteria)
    ) {
      return
    }

    if (howCouponAppliesInAvailability === 'none') {
      return createReservation(availabilityCriteria, availability)
    }

    const couponAppliesInAllRooms = staysSummary.every(
      stay => stay.room?.couponAppliesInRate,
    )

    if (
      !couponAppliesInAllRooms &&
      isDefined(availabilityCoupon) &&
      availabilityCoupon.type === 'promotional'
    ) {
      return showChangeRateModal({
        couponId: availabilityCoupon.value,
        onSecondaryButtonSelected: async () => {
          hideChangeRateModal()
          await createReservation(availabilityCriteria, availability)
        },
        onPrimaryButtonSelected: () => {
          hideChangeRateModal()
          setReservationInProgress(false)
        },
        roomNames: getRoomsThatCouponDoesNotApply(),
        isMultiroom: true,
        onClose: () => {
          setReservationInProgress(false)
        },
      })
    }

    await createReservation(availabilityCriteria, availability)
  }
  const { performMutation: handleReserve } = useAsyncMutation(
    'handleReserve',
    onReserve,
  )

  const getSelectedRoom = (): SelectedRoom | undefined => {
    if (isUndefined(staysSummary)) {
      return undefined
    }

    const summary = staysSummary[currentStayPosition]

    if (isDefined(summary) && isDefined(summary.room)) {
      return {
        roomId: summary.room.id,
        mealplanId: summary.room.mealplan.id,
        id: summary.room.rateId,
      }
    }

    return undefined
  }

  const filteredAvailability = filterAvailabilityByRoomType()

  const hasToShowNewAvailableRoomsView = isFeatureFlagEnabled(
    'FF_FEATURE_7138_NEW_AVAILABLE_ROOMS_VIEW',
  )

  return (
    <>
      {hasToShowNewAvailableRoomsView ? (
        <AvailableRoomsV2
          filteredAvailability={filteredAvailability}
          allRooms={availability?.stays[currentStayPosition]?.rooms}
          selectedRoom={getSelectedRoom()}
          reservedRate={undefined}
          onRateSelected={addRoomToStays}
          onFilterAllRoomType={startFilterAllRoomTypes}
          onFilterRoomType={setRoomTypeFilter}
          onModifyOccupancy={onModifyOccupancy}
          onModifyDates={onModifyDates}
          onModifyCoupon={onModifyCoupon}
          selectedRoomTypeFilter={selectedRoomTypeFilter}
          error={error}
          isAvailabilityLoading={
            isAvailabilityValidating ||
            isFilteringMealplan ||
            isFilteringRoomType ||
            isRoomBeingSelected
          }
          isMultiroom={true}
          reservationInProgress={reservationInProgress}
        />
      ) : (
        <AvailableRooms
          filteredAvailability={filteredAvailability}
          allRooms={availability?.stays[currentStayPosition]?.rooms}
          selectedRoom={getSelectedRoom()}
          reservedRate={undefined}
          onRateSelected={addRoomToStays}
          onFilterAllRoomType={startFilterAllRoomTypes}
          onFilterRoomType={setRoomTypeFilter}
          onModifyOccupancy={onModifyOccupancy}
          onModifyDates={onModifyDates}
          onModifyCoupon={onModifyCoupon}
          selectedRoomTypeFilter={selectedRoomTypeFilter}
          error={error}
          isAvailabilityLoading={
            isAvailabilityValidating ||
            isFilteringMealplan ||
            isFilteringRoomType ||
            isRoomBeingSelected
          }
          isMultiroom={true}
          reservationInProgress={reservationInProgress}
        />
      )}
      {!isAvailabilityLoading && (
        <StaysSummary
          onStaySelected={selectStay}
          staysSummary={staysSummary}
          currentStayPosition={currentStayPosition}
          onReserve={handleReserve}
          checkIn={availabilityCriteria?.checkIn}
          checkOut={availabilityCriteria?.checkOut}
          reservationInProgress={reservationInProgress}
        />
      )}
    </>
  )
}
