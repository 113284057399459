import { ModalV2 } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './HotelPromotionModal.module.scss'
import { HotelPromotionsHeader } from 'src/ui/views/AvailableRooms/HotelPromotionsHeader'
import { HotelPromotions } from 'src/ui/views/AvailableRooms/HotelPromotions'

interface Props {
  hotelPromotions: string[]
  onCloseModal: () => void
}

export const HotelPromotionModal = createModal(({ hotelPromotions }: Props) => {
  const { trans } = useTrans(['common', 'new-reservation'])

  return (
    <ModalV2
      size="l"
      ariaLabel={trans('hotel_alert-barcelo_exclusive')}
      mobileStyle="bottom"
      data-quantum-modal="HotelPromotionModal"
      contentClassName={styles.content}
      withButtons={false}
    >
      <HotelPromotionsHeader />
      <HotelPromotions hotelPromotions={hotelPromotions} showAll />
    </ModalV2>
  )
})
