import { FC } from 'react'
import { Tag } from 'src/ui/components'
import styles from './CouponTag.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  promotionalCoupon: PromotionalValidCoupon
  withoutCodeName?: boolean
}

export const CouponTag: FC<Props> = ({
  promotionalCoupon,
  withoutCodeName = false,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const getText = () => {
    if (promotionalCoupon.discount.type === 'PERCENTAGE') {
      if (withoutCodeName) {
        return trans(
          'available-rooms_type-rate_coupon-info-with-percentage_without-name',
          {
            percentage: Math.abs(promotionalCoupon.discount.value),
          },
        )
      }
      return trans('available-rooms_type-rate_coupon-info-with-percentage', {
        percentage: promotionalCoupon.discount.value,
        coupon: promotionalCoupon.id,
      })
    }

    return trans('available-rooms_type-rate_coupon-info', {
      coupon: promotionalCoupon.id,
    })
  }

  return (
    <Tag
      className={classNames(
        styles.couponTag,
        withoutCodeName && styles.withoutCodeName,
      )}
    >
      {getText()}
    </Tag>
  )
}
