import {
  AvailabilityRate,
  findSelectedRateInAvailability,
} from 'src/core/Availability/domain/Availability.model'
import { useMarket } from '../contexts/MarketContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'

export const useRatePrice = () => {
  const { marketPrice, calculateNightlyPricePreviousToDiscount } = useMarket()
  const { availability } = useAvailability()

  const getCurrentPrice = (rate: AvailabilityRate) => ({
    grossPrice: rate.averageNightly.grossPrice,
    netPrice: rate.averageNightly.netPrice,
  })

  const hasToShowPricePreviousToDiscountInThisMarket =
    marketPrice?.showPricePreviousToDiscount ?? false

  const getPreviousPrice = (rate: AvailabilityRate) => {
    const hasDiscount =
      isDefined(rate.averageNightly.netDiscount) &&
      isDefined(rate.averageNightly.grossDiscount)

    if (
      isDefined(rate.relatedRate) &&
      (!hasToShowPricePreviousToDiscountInThisMarket || !hasDiscount)
    ) {
      return {
        grossPrice: rate.relatedRate.rate.averageNightly.grossPrice,
        netPrice: rate.relatedRate.rate.averageNightly.netPrice,
      }
    }

    if (hasDiscount && hasToShowPricePreviousToDiscountInThisMarket) {
      return {
        grossPrice: calculateNightlyPricePreviousToDiscount(
          rate.averageNightly.grossPrice,
          rate.averageNightly.grossDiscount!,
        ),
        netPrice: calculateNightlyPricePreviousToDiscount(
          rate.averageNightly.netPrice,
          rate.averageNightly.netDiscount!,
        ),
      }
    }

    return undefined
  }

  const getPreviousPriceFromAvailability = (rate: AvailabilityRate) => {
    if (isDefined(availability)) {
      const rateInAvailability = findSelectedRateInAvailability(
        availability,
        rate.id,
      )
      if (isDefined(rateInAvailability)) {
        return getPreviousPrice(rateInAvailability)
      }
    }
  }

  return {
    getCurrentPrice,
    getPreviousPrice,
    getPreviousPriceFromAvailability,
  }
}
