import styles from './Room.module.scss'

import {
  AvailabilityRoom,
  getAllRatesIdsFromRoom,
} from 'src/core/Availability/domain/Availability.model'
import { Header } from './Header'
import { Info } from './Info'
import { FC } from 'react'
import {
  ReservedRoomAndRate,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useModal } from 'src/ui/hooks/useModal'
import { RoomDetailsModal } from 'src/ui/views/AvailableRooms/RoomDetailsModal'
import { RateSelectorController as RateSelector } from './RateSelector/RateSelector.controller'
import { classNames } from 'src/ui/utils/classnames'
import { OnRateSelected } from '../AvailableRooms.model'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { RateSelectorV2Controller as RateSelectorV2 } from 'src/ui/views/AvailableRooms/Room/RateSelector/RateSelectorV2.controller'

interface Props {
  room: AvailabilityRoom
  hotel: Hotel | undefined
  reservedRoomAndRate?: ReservedRoomAndRate
  onRateSelected: OnRateSelected
  selectedRoom: SelectedRoom | undefined
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
}

export const Room: FC<Props> = ({
  room,
  hotel,
  reservedRoomAndRate,
  onRateSelected,
  selectedRoom,
  isMultiroom,
  isOneNight,
  totalNightsFromAvailability,
  reservationInProgress,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { showModal: showRoomDetailModal } = useModal(RoomDetailsModal)

  const { name: roomName } = room
  const hasMealplans = !isEmpty(room.mealplans)

  if (!hasMealplans) {
    return null
  }

  const handleShowRoomDetails = async () => {
    if (isDefined(hotel)) {
      await showRoomDetailModal({ room, hotel })
    }
  }

  const selectedRateInRoom =
    selectedRoom?.roomId === room.id ? selectedRoom : undefined

  const isSelected =
    isDefined(selectedRateInRoom) &&
    getAllRatesIdsFromRoom(room).includes(selectedRateInRoom?.id)

  const hasToShowNewAvailableRoomsView = isFeatureFlagEnabled(
    'FF_FEATURE_7138_NEW_AVAILABLE_ROOMS_VIEW',
  )

  return (
    <div className={isSelected ? styles.darkCorner : undefined}>
      <article
        aria-label={trans('available-rooms_room-details_aria-label', {
          roomName,
        })}
        className={classNames(
          styles.container,
          isSelected && styles.isSelected,
        )}
        data-room-code={room.id}
        data-target-room-inventory={room.inventory}
        data-target-room-code={room.id}
        data-testid={`room-${room.id}`}
      >
        {isSelected && (
          <div className={styles.selected}>
            {trans('availability_room_mealplan_rate_your-selection')}
          </div>
        )}

        <Header room={room} onRoomNameClick={handleShowRoomDetails} />

        <section className={styles.info}>
          <Info
            hotel={hotel}
            room={room}
            trans={trans}
            onMoreInfoClick={handleShowRoomDetails}
          />
        </section>

        <section
          className={styles.rateSelectorContainer}
          data-testid={`rate-selector_${room.id}`}
        >
          {hasToShowNewAvailableRoomsView ? (
            <RateSelectorV2
              room={room}
              reservedRoomAndRate={reservedRoomAndRate}
              onRateSelected={onRateSelected}
              selectedRoom={selectedRoom}
              isMultiroom={isMultiroom}
              isOneNight={isOneNight}
              totalNightsFromAvailability={totalNightsFromAvailability}
              reservationInProgress={reservationInProgress}
              isSelected={isSelected}
            />
          ) : (
            <RateSelector
              room={room}
              reservedRoomAndRate={reservedRoomAndRate}
              onRateSelected={onRateSelected}
              selectedRoom={selectedRoom}
              isMultiroom={isMultiroom}
              isOneNight={isOneNight}
              totalNightsFromAvailability={totalNightsFromAvailability}
              reservationInProgress={reservationInProgress}
              isSelected={isSelected}
            />
          )}
        </section>
      </article>
    </div>
  )
}
