import { FC } from 'react'
import { AvailabilityRate } from 'src/core/Availability/domain/Availability.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text } from 'src/ui/components'
import { TaxInformation } from '../TaxInformation'
import { Translate } from 'src/ui/i18n/Translate'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CouponTag } from '../CouponTag'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { StrikethroughPrice } from '../StrikethroughPrice'
import { classNames } from 'src/ui/utils/classnames'
import { Price } from 'src/ui/components/molecules/Price'
import { useRatePrice } from 'src/ui/hooks/useRatePrice'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

type Props = {
  rate: AvailabilityRate
  isOneNight: boolean
  howCouponApplies: 'both' | 'some' | undefined
  className?: string
}

export const CurrentRatePriceDesktop: FC<Props> = ({
  rate,
  isOneNight,
  howCouponApplies,
  className,
}) => {
  const {
    getCurrentPrice,
    getPreviousPrice,
    getPreviousPriceFromAvailability,
  } = useRatePrice()
  const { trans } = useTrans(['new-reservation'])
  const { promotionalCoupon } = useCoupons()
  const hasToShowNewAvailableRoomsView = isFeatureFlagEnabled(
    'FF_FEATURE_7138_NEW_AVAILABLE_ROOMS_VIEW',
  )

  const currentPrice = getCurrentPrice(rate)
  const previousPrice = hasToShowNewAvailableRoomsView
    ? getPreviousPriceFromAvailability(rate)
    : getPreviousPrice(rate)

  return (
    <Flex
      alignItems="flex-end"
      direction="column"
      className={classNames('mb-xs', className)}
    >
      {isDefined(promotionalCoupon) && howCouponApplies === 'both' && (
        <CouponTag promotionalCoupon={promotionalCoupon} />
      )}
      <div>
        {isDefined(previousPrice) && (
          <StrikethroughPrice
            ariaLabel={trans('available-rooms_previous-price')}
            grossPrice={previousPrice.grossPrice}
            netPrice={previousPrice.netPrice}
          />
        )}
        <Price
          ariaLabel={trans('available-rooms_current-price')}
          fontStyle="xl"
          color="dark"
          grossPrice={currentPrice.grossPrice}
          netPrice={currentPrice.netPrice}
          round="off"
        />
      </div>
      <Text fontStyle="s-500" color="dark">
        {trans('available-rooms_price-per-night')}
      </Text>

      {!isOneNight && (
        <Text fontStyle="xs-500" color="dark">
          <Translate
            i18nKey="new-reservation:available-rooms_total-stay"
            components={{
              price: (
                <Price
                  ariaLabel={trans('available-rooms_current-price')}
                  fontStyle="s"
                  fontWeight="mid"
                  color="dark"
                  grossPrice={rate.total.grossPrice}
                  netPrice={rate.total.netPrice}
                  round="off"
                />
              ),
            }}
          />
        </Text>
      )}
      <TaxInformation />
    </Flex>
  )
}
