import { FC, ReactNode, useEffect } from 'react'
import mainLayoutStyles from '../layout.module.scss'
import styles from './AvailableRoomsLayout.module.scss'
import Head from 'next/head'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Header } from 'src/ui/views/_layouts/Header'
import { User } from 'src/core/User/domain/User'
import { Footer } from 'src/ui/views/_layouts/Footer'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { Alert } from 'src/core/Hotel/domain/Alert.model'
import { Sanitizer } from 'src/ui/views/_components/Sanitizer'
import { BookingStepper } from 'src/ui/views/_components/BookingStepper'
import couponRibbonStyles from '../Header/PromotionalCouponRibbon/PromotionalCouponRibbon.module.scss' // Necesaria relativa para knip
import { Media } from 'src/ui/styles/objects/Media'
import { useCart } from 'src/ui/contexts/CartContext'
import { TabTitle } from 'src/ui/components/atoms/TabTitle'

interface props {
  children: ReactNode
  onLogin: () => void
  onLogout: () => Promise<void>
  user: User | undefined
  hotelMarketingAlert: Alert | undefined
  showAlert: boolean
  onHideMarketingAlert: () => void
}

export const AvailableRoomsLayout: FC<props> = ({
  children,
  onLogin,
  user,
  onLogout,
  hotelMarketingAlert,
  onHideMarketingAlert,
  showAlert,
}) => {
  const { trans } = useTrans(['pages'])
  const { cart } = useCart()

  useEffect(() => {
    if (isDefined(window)) {
      window.history.scrollRestoration = 'manual'
      return () => {
        window.history.scrollRestoration = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div id="coupon-ribbon-root" className={couponRibbonStyles.container} />

      <TabTitle
        activeTitleKey="available-rooms-layout_page-title_active"
        inactiveTitleKey="available-rooms-layout_page-title_inactive"
      />
      <Head>
        <meta
          property="og:title"
          content={trans('available-rooms-layout_page-title')}
          key="AvailableRoomsBookingEngine"
        />
      </Head>
      {isDefined(hotelMarketingAlert) && showAlert && (
        <Flex
          className={styles.marketingAlert}
          justifyContent="space-between"
          alignItems="center"
          gap="xs"
        >
          <Text fontStyle="s-700" className={styles.alertText}>
            <Sanitizer htmlText={hotelMarketingAlert.description} />
          </Text>
          <button
            className={styles.exitButton}
            onClick={onHideMarketingAlert}
            aria-label={trans('alert_close-button_aria-label')}
          >
            <Icon size="l" color="text-dark" icon={CloseIcon} />
          </button>
        </Flex>
      )}
      <div className={mainLayoutStyles.appContainer}>
        <Header onLogin={onLogin} onLogout={onLogout} user={user} />
        <Media tablet laptop desktop>
          <div className={styles.bookingStepperWrapper}>
            <BookingStepper currentStep={1} />
          </div>
        </Media>
        <main
          className={mainLayoutStyles.mainContainer}
          data-target-view="available-rooms"
          data-quantum-itinerary-number={
            isDefined(cart) ? cart.itineraryNumber : undefined
          }
        >
          {children}
        </main>
        <Footer />
      </div>
      <div id="stays-summary-root" className={styles.staysSummaryRoot} />
    </>
  )
}
