import { Divider, ModalV2, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import GastroIcon from 'src/ui/components/atoms/Icon/_icons/general/GastroIcon'
import { AvailabilityMealplanId } from 'src/core/Availability/domain/Availability.model'
import { existsI18nTranslate } from 'src/ui/i18n/existsI18nTranslate'

interface Props {
  mealplanIds: AvailabilityMealplanId[]
}

export const MealplansModal = createModal(
  ({ mealplanIds: mealplansIds }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    return (
      <ModalV2
        size="l"
        ariaLabel={trans('confirm-exit-modal_aria-label')}
        withButtons={false}
        customHeader={
          <Flex direction="row" alignItems="center" gap="m">
            <Icon size="l" icon={GastroIcon} />
            <Text
              fontStyle={{ mobile: 'l-700', laptop: '2xl-700' }}
              color="dark"
              as="h1"
              centered
            >
              {trans('mealplan-title')}
            </Text>
          </Flex>
        }
      >
        <Flex direction="column">
          {mealplansIds.map((mealplanId, index) => {
            const titleLocaleKey = `mealplan-description_${mealplanId}-title`
            const disclaimerLocaleKey = `mealplan-description_${mealplanId}-disclaimer`

            if (!existsI18nTranslate(titleLocaleKey, trans)) {
              return null
            }

            return (
              <div key={mealplanId} className="w-full">
                {index !== 0 && (
                  <Divider dir="horizontal" className="mt-m mb-m" />
                )}
                <Flex direction="column" justifyContent="flex-start" gap="xxs">
                  <Text
                    as="h2"
                    fontStyle={{ mobile: 'm-700', laptop: 'l-700' }}
                    color="dark"
                  >
                    {trans(`mealplan-description_${mealplanId}-title`)}
                  </Text>
                  <Text
                    as="p"
                    fontStyle={{ mobile: 's-300', laptop: 'm-300' }}
                    color="dark"
                  >
                    {trans(`mealplan-description_${mealplanId}-body`)}
                  </Text>
                  {existsI18nTranslate(disclaimerLocaleKey, trans) && (
                    <Text
                      as="p"
                      fontStyle="xs-300"
                      className="mt-m"
                      color="dark"
                    >
                      {trans(`mealplan-description_${mealplanId}-disclaimer`)}
                    </Text>
                  )}
                </Flex>
              </div>
            )
          })}
        </Flex>
      </ModalV2>
    )
  },
)
