import {
  isDefined,
  isEmpty,
  isEqual,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { useReducer } from 'react'
import { CurrencyISOCode, Price } from 'src/core/Shared/domain/Price.model'
import { Cart } from 'src/core/Cart/domain/Cart.model'
import {
  Availability,
  AvailabilityRate,
  AvailabilityCriteria,
  doesCouponAppliesInSelectedRate,
  findSelectedRate,
} from 'src/core/Availability/domain/Availability.model'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'
import { SelectedMealplan } from 'src/core/Shared/domain/Reservation.model'

export const useStaysSummary = () => useReducer(reducer, initialState)

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.SELECT_RATE:
      return selectRate(state, action)
    case ActionKind.SELECT_STAY:
      return {
        ...state,
        currentStayPosition: action.payload.selectedStayPosition,
      }
    case ActionKind.LOAD_STAYS_FROM_CART:
      return loadStaysFromCart(state, action)
    case ActionKind.LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA:
      return loadStaysFromAvailabilityCriteria(state, action)
    case ActionKind.UPDATE_AVAILABILITY_ON_STAYS_SUMMARY:
      return updateAvailabilityOnStaysSummary(state, action)
    default:
      throw new Error()
  }
}

const selectRate = (
  state: State,
  action: { type: ActionKind.SELECT_RATE; payload: SelectRatePayload },
) => {
  const updatedStaysSummary = state.staysSummary.map((staySummary, index) => {
    if (index !== state.currentStayPosition) {
      return staySummary
    }

    return {
      ...staySummary,
      room: {
        id: action.payload.roomId,
        name: action.payload.roomName,
        mealplan: action.payload.mealplan,
        rateId: action.payload.rate.id,
        rateIsMember: action.payload.rate.isMember,
        averageNetPriceNightly: action.payload.rate.averageNightly.netPrice,
        averageGrossPriceNightly: action.payload.rate.averageNightly.grossPrice,
        netPrice: action.payload.rate.total.netPrice,
        grossPrice: action.payload.rate.total.grossPrice,
        nonConvertedNetPrice: action.payload.rate.nonConvertedTotal.netPrice,
        nonConvertedGrossPrice:
          action.payload.rate.nonConvertedTotal.grossPrice,
        couponAppliesInRate: isDefined(action.payload.availability)
          ? doesCouponAppliesInSelectedRate(
              action.payload.rate,
              action.payload.mealplan,
              action.payload.roomName,
              action.payload.availability,
            )
          : undefined,
      },
    }
  })

  const getNextStayPosition = () => {
    const nextStayPosition = updatedStaysSummary.findIndex(staySummary =>
      isUndefined(staySummary.room),
    )

    if (nextStayPosition === -1) {
      return state.currentStayPosition
    }

    return nextStayPosition
  }

  return {
    ...state,
    staysSummary: updatedStaysSummary as StaySummary[],
    currentStayPosition: getNextStayPosition(),
    checkIn: action.payload.checkIn,
    checkOut: action.payload.checkOut,
    selectedCurrency:
      action.payload.rate.total.grossPrice.currency ||
      action.payload.rate.total.netPrice.currency,
  }
}

const updateAvailabilityOnStaysSummary = (
  state: State,
  action: {
    type: ActionKind.UPDATE_AVAILABILITY_ON_STAYS_SUMMARY
    payload: UpdateCouponFromSelectedRatePayload
  },
) => {
  if (isEmpty(state.staysSummary)) {
    return state
  }

  const updatedStaysSummary = state.staysSummary.map(staySummary => {
    const room = staySummary.room
    if (isUndefined(room)) {
      return { ...staySummary, room: undefined }
    }

    const mealplan = room.mealplan
    const roomName = room.name
    const rateId = room.rateId
    const rateIsMember = room.rateIsMember
    const roomId = room.id
    const rate = findSelectedRate(
      action.payload.availability,
      mealplan,
      roomName,
      rateId,
    )
    if (isUndefined(rate)) {
      return { ...staySummary, room: undefined }
    }

    return {
      ...staySummary,
      room: {
        id: roomId,
        name: roomName,
        mealplan: mealplan,
        rateId: rateId,
        rateIsMember: rateIsMember,
        averageNetPriceNightly: rate.averageNightly.netPrice,
        averageGrossPriceNightly: rate.averageNightly.grossPrice,
        nonConvertedNetPrice: rate.nonConvertedTotal.netPrice,
        nonConvertedGrossPrice: rate.nonConvertedTotal.grossPrice,
        netPrice: rate.total.netPrice,
        grossPrice: rate.total.grossPrice,
        couponAppliesInRate: isDefined(action.payload.availability)
          ? doesCouponAppliesInSelectedRate(
              rate,
              mealplan,
              roomName,
              action.payload.availability,
            )
          : undefined,
      },
    }
  })
  return {
    ...state,
    staysSummary: updatedStaysSummary as StaySummary[],
  }
}

const loadStaysFromCart = (
  state: State,
  action: {
    type: ActionKind.LOAD_STAYS_FROM_CART
    payload: LoadStaysFromCartPayload
  },
) => {
  const getCurrentState = () => {
    return {
      ...state,
      staysSummary: state.staysSummary,
      currentStayPosition: state.currentStayPosition,
      checkIn: state.checkIn,
      checkOut: state.checkOut,
      selectedCurrency: state.staysSummary[0]?.room?.grossPrice.currency,
    }
  }

  const isCurrencyDifferent =
    isDefined(state.selectedCurrency) &&
    action.payload.availabilityCriteria.currency !== state.selectedCurrency
  if (isCurrencyDifferent) {
    return {
      ...getCurrentState(),
      staysSummary: mapAvailabilityCriteriaIntoStaysSummary(
        action.payload.availabilityCriteria,
      ),
      currentStayPosition: action.payload.defaultStayPosition,
    }
  }

  const hasSelectedStays =
    !isEmpty(state.staysSummary) &&
    state.staysSummary.every(staySummary => isDefined(staySummary.room))
  if (hasSelectedStays) {
    return getCurrentState()
  }

  const stayPositionWhenSyncingCart = action.payload.defaultStayPosition
  return {
    ...state,
    staysSummary: mapCartIntoStaysSummary(
      action.payload.cart,
      action.payload.availabilityCoupon,
      action.payload.availability,
    ),
    currentStayPosition: stayPositionWhenSyncingCart,
    checkIn: action.payload.cart.checkIn.toString(),
    checkOut: action.payload.cart.checkOut.toString(),
    selectedCurrency: action.payload.cart.prices.total.converted.currency,
  }
}

const mapCartIntoStaysSummary = (
  cart: Cart,
  coupon: AvailabilityCoupon | undefined,
  availability?: Availability,
): StaySummary[] =>
  cart.roomStays.map(({ room, occupancy, rate }) => ({
    occupancy: occupancy,
    room: {
      id: room.id,
      name: room.name,
      rateId: rate.id,
      rateIsMember: rate.isMember,
      couponAppliesInRate:
        isDefined(coupon) && isDefined(availability)
          ? doesCouponAppliesInSelectedRate(
              rate,
              rate.mealPlan,
              room.name,
              availability,
            )
          : undefined,
      grossPrice: rate.total.grossPrice,
      netPrice: rate.total.netPrice,
      mealplan: rate.mealPlan,
      nonConvertedNetPrice: rate.nonConvertedTotal.netPrice,
      nonConvertedGrossPrice: rate.nonConvertedTotal.grossPrice,
      averageNetPriceNightly: rate.averageNightly.netPrice,
      averageGrossPriceNightly: rate.averageNightly.grossPrice,
    },
  }))

const loadStaysFromAvailabilityCriteria = (
  state: State,
  action: {
    type: ActionKind.LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA
    payload: LoadEmptyStaysFromAvailabilityCriteriaPayload
  },
) => {
  const stayPositionWhenSyncingAvailabilityCriteria = 0

  const isSameCriteria = isEquivalentToCriteria(
    state.staysSummary,
    action.payload.availabilityCriteria,
    state.checkIn !== initialState.checkIn
      ? Time.fromString(state.checkIn).toDate()
      : action.payload.availabilityCriteria.checkIn,
    state.checkOut !== initialState.checkOut
      ? Time.fromString(state.checkOut).toDate()
      : action.payload.availabilityCriteria.checkOut,
    state.selectedCurrency === action.payload.availabilityCriteria.currency,
  )

  if (isSameCriteria) {
    return state
  }

  return {
    ...state,
    staysSummary: mapAvailabilityCriteriaIntoStaysSummary(
      action.payload.availabilityCriteria,
    ),
    currentStayPosition: stayPositionWhenSyncingAvailabilityCriteria,
    checkIn: initialState.checkIn,
    checkOut: initialState.checkOut,
    selectedCurrency: undefined,
  }
}

export const isEquivalentToCriteria = (
  staysSummary: StaySummary[],
  availabilityCriteria: AvailabilityCriteria,
  checkIn: Date,
  checkOut: Date,
  isSameCurrency: boolean,
): boolean => {
  const isSameCheckIn = datesManager.areDatesTheSame(
    checkIn,
    availabilityCriteria.checkIn,
  )

  const isSameCheckOut = datesManager.areDatesTheSame(
    checkOut,
    availabilityCriteria.checkOut,
  )

  if (!isSameCheckIn || !isSameCheckOut) {
    return false
  }

  if (!isSameCurrency) {
    return false
  }

  const sameNumberOfRooms =
    staysSummary.length === availabilityCriteria.adults.length

  if (!sameNumberOfRooms) {
    return false
  }

  const sameOccupancy = staysSummary.every(({ occupancy }, index) => {
    const childrenAges = isDefined(availabilityCriteria.childrenAges)
      ? availabilityCriteria.childrenAges[index]
      : []

    const availabilityCriteriaOccupancy = {
      adults: availabilityCriteria.adults[index],
      children:
        availabilityCriteria.children[index] === 0
          ? undefined
          : availabilityCriteria.children[index],
      childrenAges,
    }

    return isEqual(occupancy, availabilityCriteriaOccupancy)
  })

  return sameOccupancy
}

export const mapAvailabilityCriteriaIntoStaysSummary = (
  availabilityCriteria: AvailabilityCriteria,
): StaySummary[] =>
  availabilityCriteria.adults.map((adult, index) => ({
    occupancy: {
      adults: adult,
      children:
        availabilityCriteria.children[index] === 0
          ? undefined
          : availabilityCriteria.children[index],
      childrenAges: isDefined(availabilityCriteria.childrenAges)
        ? availabilityCriteria.childrenAges[index]
        : undefined,
    },
    room: undefined,
  }))

const initialState: State = {
  staysSummary: [],
  currentStayPosition: 0,
  checkIn: '',
  checkOut: '',
  selectedCurrency: undefined,
}

interface StaySummaryRoom {
  id: string
  name: string
  mealplan: SelectedMealplan
  rateId: string
  rateIsMember: boolean
  couponAppliesInRate?: boolean
  netPrice: Price
  grossPrice: Price
  averageNetPriceNightly: Price
  averageGrossPriceNightly: Price
  nonConvertedNetPrice: Price
  nonConvertedGrossPrice: Price
}

export interface StaySummary {
  occupancy: {
    adults: number
    children?: number
    childrenAges?: number[]
  }
  room?: StaySummaryRoom
}

interface State {
  staysSummary: Array<StaySummary>
  currentStayPosition: number
  checkIn: string
  checkOut: string
  selectedCurrency: CurrencyISOCode | undefined
}

export enum ActionKind {
  SELECT_RATE = 'SELECT_RATE',
  SELECT_STAY = 'SELECT_STAY',
  LOAD_STAYS_FROM_CART = 'LOAD_STAYS_FROM_CART',
  LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA = 'LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA',
  UPDATE_AVAILABILITY_ON_STAYS_SUMMARY = 'UPDATE_AVAILABILITY_ON_STAYS_SUMMARY',
}

type SelectStayPayload = {
  selectedStayPosition: number
}

type LoadStaysFromCartPayload = {
  cart: Cart
  availabilityCriteria: AvailabilityCriteria
  availabilityCoupon: AvailabilityCoupon | undefined
  availability: Availability
  defaultStayPosition: number
}

type LoadEmptyStaysFromAvailabilityCriteriaPayload = {
  availabilityCriteria: AvailabilityCriteria
}

type SelectRatePayload = {
  roomId: string
  roomName: string
  mealplan: SelectedMealplan
  rate: AvailabilityRate
  checkIn: string
  checkOut: string
  availability?: Availability
}

type UpdateCouponFromSelectedRatePayload = {
  availability: Availability
}

type Action =
  | { type: ActionKind.SELECT_STAY; payload: SelectStayPayload }
  | { type: ActionKind.LOAD_STAYS_FROM_CART; payload: LoadStaysFromCartPayload }
  | {
      type: ActionKind.LOAD_EMPTY_STAYS_FROM_AVAILABILITY_CRITERIA
      payload: LoadEmptyStaysFromAvailabilityCriteriaPayload
    }
  | { type: ActionKind.SELECT_RATE; payload: SelectRatePayload }
  | {
      type: ActionKind.UPDATE_AVAILABILITY_ON_STAYS_SUMMARY
      payload: UpdateCouponFromSelectedRatePayload
    }
