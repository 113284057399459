import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { ViewingAccelerator } from 'src/core/Shared/domain/Reservation.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useAccelerators } from 'src/ui/hooks/useAccelerators'

export const useAvailabilityAccelerators = (room: AvailabilityRoom) => {
  const { queryUtils } = useApplicationRouter()
  const hotelId = queryUtils.getRawParam('hotel')
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')
  const inventory = room.inventory

  const { data: viewingAccelerators } = useQueryService<ViewingAccelerator[]>(
    'get-viewing-now-accelerators',
    isDefined(hotelId) && inventory > 3 ? [hotelId] : null,
    () =>
      container.resolve('getViewingNowAccelerators')(
        hotelId!,
        checkIn!,
        checkOut!,
      ),
  )

  return useAccelerators(room.id, inventory, viewingAccelerators)
}
