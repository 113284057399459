import { FC } from 'react'
import styles from './TypeRateSelector.module.scss'
import {
  AvailabilityRate,
  AvailabilityFlatRate,
  AvailabilityRoom,
  isCouponAppliedInRate,
} from 'src/core/Availability/domain/Availability.model'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Tag, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { InputRadio } from '../InputRadio'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import { useModal } from 'src/ui/hooks/useModal'
import { MemberRateBenefitsModal } from 'src/ui/views/AvailableRooms/Room/RateSelector/TypeRateSelector/MemberRateBenefitsModal'
import { useUser } from 'src/ui/contexts/UserContext'
import { CouponTag } from 'src/ui/views/AvailableRooms/Room/RateSelector/CouponTag'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { useMarket } from 'src/ui/contexts/MarketContext'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { memberRateHasTag, SelectableRates } from '../RateSelector.model'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { MyBarceloBenefitsLogoHorizontal } from 'src/ui/views/_components/MyBarceloBenefitsLogo'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { LiteLandingModal } from 'src/ui/views/AvailableRooms/LiteLandingModal'
import { useRateSelectorV2 } from 'src/ui/contexts/RateSelectorContextV2'

interface Props {
  room: AvailabilityRoom
  selectableRates: SelectableRates
  currentRate: AvailabilityFlatRate
  howCouponApplies: 'both' | 'some' | undefined
  totalNightsFromAvailability: number
}

export const TypeRateSelectorV2: FC<Props> = ({
  room,
  selectableRates,
  currentRate,
  howCouponApplies,
  totalNightsFromAvailability,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { promotionalCoupon } = useCoupons()
  const coupon = howCouponApplies === 'some' ? promotionalCoupon : undefined
  const { formatPrice } = usePriceFormatter()
  const { marketPrice, calculatePricePreviousToDiscount } = useMarket()
  const { isMyBarceloToggleSelected } = useRateSelectorV2()

  const isMemberRateDisabled = isUndefined(selectableRates.memberRate)
  const isStandardRateDisabled = isUndefined(selectableRates.standardRate)

  const hasMarketStrikethroughPrice =
    marketPrice?.showPricePreviousToDiscount ?? false

  const hasDiscount = (rate: AvailabilityRate) => {
    return (
      isDefined(rate?.total.netDiscount) && isDefined(rate?.total.grossDiscount)
    )
  }
  const stayStrikethroughPrice = (rate: AvailabilityRate) => {
    if (hasDiscount(rate) && hasMarketStrikethroughPrice) {
      return formatPrice(
        calculatePricePreviousToDiscount(
          rate.total.grossPrice,
          rate.total.grossDiscount!,
        ),
        { roundOff: true },
        calculatePricePreviousToDiscount(
          rate.total.netPrice,
          rate.total.netDiscount!,
        ),
      )
    }
    return false
  }

  const averageNightlyStrikethroughPrice = (rate: AvailabilityRate) => {
    if (hasDiscount(rate) && hasMarketStrikethroughPrice) {
      const grossPricePreviousToDiscount = calculatePricePreviousToDiscount(
        rate.total.grossPrice,
        rate.total.grossDiscount!,
      )
      const netPricePreviousToDiscount = calculatePricePreviousToDiscount(
        rate.total.netPrice,
        rate.total.netDiscount!,
      )
      return formatPrice(
        {
          value:
            grossPricePreviousToDiscount.value / totalNightsFromAvailability,
          currency: grossPricePreviousToDiscount.currency,
        },

        { roundOff: true },
        {
          value: netPricePreviousToDiscount.value / totalNightsFromAvailability,
          currency: grossPricePreviousToDiscount.currency,
        },
      )
    }
    return false
  }

  return (
    <div>
      <Text as="h4" fontStyle="l-700" color="dark" className="mb-s">
        {trans('available-rooms_type-rate_title')}
      </Text>

      <Flex gap="s" direction="column" alignItems="stretch">
        {isMyBarceloToggleSelected && !isMemberRateDisabled && (
          <RateOption isDisabled={isMemberRateDisabled}>
            <MemberRateOption
              room={room}
              selectableRates={selectableRates}
              currentRate={currentRate}
              isRateDisabled={isMemberRateDisabled}
              promotionalCoupon={coupon}
              stayStrikethroughPrice={stayStrikethroughPrice}
              averageNightlyStrikethroughPrice={
                averageNightlyStrikethroughPrice
              }
            />
          </RateOption>
        )}

        {!isMyBarceloToggleSelected && !isStandardRateDisabled && (
          <RateOption isDisabled={isStandardRateDisabled}>
            <StandardRateOption
              room={room}
              selectableRates={selectableRates}
              currentRate={currentRate}
              isRateDisabled={isStandardRateDisabled}
              promotionalCoupon={coupon}
              stayStrikethroughPrice={stayStrikethroughPrice}
              averageNightlyStrikethroughPrice={
                averageNightlyStrikethroughPrice
              }
            />
          </RateOption>
        )}
      </Flex>
    </div>
  )
}

const RateOption: FC<{
  isDisabled: boolean
  children: JSX.Element
}> = ({ isDisabled, children }) => {
  const { trans } = useTrans(['new-reservation'])

  return isDisabled ? (
    <Tooltip
      triggerElement={children}
      isTriggerElementDisabled
      tooltipContent={trans('available-rooms_type-rate_tooltip-not-available')}
      placement="top-end"
      arrowOffset={12}
    />
  ) : (
    children
  )
}

interface RateProps {
  room: AvailabilityRoom
  selectableRates: SelectableRates
  currentRate: AvailabilityFlatRate
  isRateDisabled: boolean
  promotionalCoupon: PromotionalValidCoupon | undefined
  stayStrikethroughPrice: (rate: AvailabilityFlatRate) => string | false
  averageNightlyStrikethroughPrice: (
    rate: AvailabilityFlatRate,
  ) => string | false
}

const StandardRateOption: FC<RateProps> = ({
  room,
  selectableRates,
  currentRate,
  isRateDisabled,
  promotionalCoupon,
  stayStrikethroughPrice,
  averageNightlyStrikethroughPrice,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { formatPrice } = usePriceFormatter()
  const { standardRate } = selectableRates

  const hasTagInStandarRate =
    !isRateDisabled &&
    isDefined(promotionalCoupon) &&
    isDefined(standardRate) &&
    isCouponAppliedInRate(standardRate, promotionalCoupon)

  return (
    <InputRadio
      name={`rate-${room.id}`}
      id={`rate-${room.id}_${standardRate?.id}`}
      isSelected={currentRate.id === standardRate?.id}
      onChange={() => {}}
      isDisabled={isRateDisabled}
      withTag={hasTagInStandarRate}
      data-target-stay-price={
        standardRate &&
        formatPrice(
          standardRate.total.grossPrice,
          { roundOff: true },
          standardRate.total.netPrice,
        )
      }
      data-target-average-nightly-price={
        standardRate &&
        formatPrice(
          standardRate.averageNightly.grossPrice,
          { roundOff: true },
          standardRate.averageNightly.netPrice,
        )
      }
      data-target-stay-linethrough-price={
        standardRate && stayStrikethroughPrice(standardRate)
      }
      data-target-average-nightly-linethrough-price={
        standardRate && averageNightlyStrikethroughPrice(standardRate)
      }
    >
      {hasTagInStandarRate && (
        <div className={styles.tagsWrapper}>
          <CouponTag promotionalCoupon={promotionalCoupon} />
        </div>
      )}
      <Text as="p" fontStyle="m-700" className="ml-xl mb-xxs" color="dark">
        {trans('available-rooms_type-rate_standard-title')}
      </Text>
      <Text as="p" fontStyle="s-500" color="dark" className="ml-xl">
        {trans('available-rooms_type-rate_standard-description')}
      </Text>
    </InputRadio>
  )
}

const MemberRateOption: FC<RateProps> = ({
  room,
  selectableRates,
  currentRate,
  isRateDisabled,
  promotionalCoupon,
  stayStrikethroughPrice,
  averageNightlyStrikethroughPrice,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { user } = useUser()
  const { showModal: showMemberRateBenefitsModal } = useModal(
    MemberRateBenefitsModal,
  )
  const {
    showModal: showLiteLandingModal,
    removeModal: removeLiteLandingModal,
  } = useModal(LiteLandingModal)
  const { formatPrice } = usePriceFormatter()
  const { memberRate } = selectableRates

  const couponAppliesInRate =
    isDefined(promotionalCoupon) &&
    isDefined(memberRate) &&
    isCouponAppliedInRate(memberRate, promotionalCoupon)

  const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
    'FF_FEATURE_5847_MY_BARCELO_REVAMP',
  )

  const handleShowMoreInfo = async () => {
    if (hasNewMyBarceloLoyalties) {
      await showLiteLandingModal({
        userLevel: user?.level ?? UserLevelV2.NotFound,
        onClose: removeLiteLandingModal,
      })
    }

    await showMemberRateBenefitsModal({ user })
  }

  return (
    <InputRadio
      name={`rate-${room.id}`}
      id={`rate-${room.id}_${memberRate?.id}`}
      isSelected={currentRate.id === memberRate?.id}
      onChange={() => {}}
      isDisabled={isRateDisabled}
      withTag={memberRateHasTag(memberRate)}
      data-target-stay-price={
        memberRate &&
        formatPrice(
          memberRate.total.grossPrice,
          { roundOff: true },
          memberRate.total.netPrice,
        )
      }
      data-target-average-nightly-price={
        memberRate &&
        formatPrice(
          memberRate.averageNightly.grossPrice,
          { roundOff: true },
          memberRate.averageNightly.netPrice,
        )
      }
      data-target-stay-linethrough-price={
        memberRate && stayStrikethroughPrice(memberRate)
      }
      data-target-average-nightly-linethrough-price={
        memberRate && averageNightlyStrikethroughPrice(memberRate)
      }
    >
      {isDefined(memberRate) && (
        <div className={styles.tagsWrapper}>
          <Tag className={styles.bestPriceTag}>
            {trans('available-rooms_type-rate_best-price')}
          </Tag>
          {couponAppliesInRate && (
            <CouponTag promotionalCoupon={promotionalCoupon} />
          )}
        </div>
      )}
      <Text as="p" fontStyle="m-700" className="ml-xl mr-2xl mb-xxs">
        {hasNewMyBarceloLoyalties ? (
          <MyBarceloBenefitsLogoHorizontal className={styles.myBarceloLogo} />
        ) : (
          trans('available-rooms_type-rate_member-title')
        )}
      </Text>
      {!isRateDisabled && (
        <ButtonLink
          onClick={handleShowMoreInfo}
          aria-label={trans('available-rooms_rate-member_more-info-text')}
          className={styles.infoButton}
        >
          <Text fontStyle="s-link">
            {trans('available-rooms_rate-member_more-info-text')}
          </Text>
        </ButtonLink>
      )}
      <Text as="p" fontStyle="s-500" color="dark" className="ml-xl">
        {trans('available-rooms_type-rate_member-description')}
      </Text>
    </InputRadio>
  )
}
