import { classNames } from 'src/ui/utils/classnames'
import styles from './ReserveButton.module.scss'
import { Loader } from 'src/ui/components'
import { FC, MouseEventHandler, ReactNode } from 'react'

interface Props {
  isLoading?: boolean
  isDisabled?: boolean
  children: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ReserveButton: FC<Props> = ({
  isDisabled,
  isLoading,
  onClick,
  children,
}) => {
  return (
    <button
      className={classNames(styles.button)}
      onClick={onClick}
      disabled={isDisabled || isLoading}
    >
      <div className={classNames(isLoading && styles.contentLoading)}>
        {children}
      </div>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader variant="inverse" size="l" />
        </div>
      )}
    </button>
  )
}
