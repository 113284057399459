import styles from './CouponsSummary.module.scss'
import React, { FC } from 'react'
import { Text } from 'src/ui/components'
import { CouponsCount } from './CouponsCount'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Icon } from 'src/ui/components/atoms/Icon'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { Media } from 'src/ui/styles/objects/Media'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CouponTag } from 'src/ui/views/AvailableRooms/Room/RateSelector/CouponTag'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Translate } from 'src/ui/i18n/Translate'

interface Props {
  onOpenEditor: () => void
}
export const CouponsSummary: FC<Props> = ({ onOpenEditor }) => {
  const { trans } = useTrans(['new-reservation'])
  const languageConfig = useLanguageConfig()
  const isRTL = languageConfig.properties.isRTL

  const { promotionalCoupon, howCouponAppliesInAvailability } = useCoupons()

  const hasToShowCoupon =
    isDefined(promotionalCoupon) && howCouponAppliesInAvailability !== 'none'

  return (
    <div className={styles.container} onClick={onOpenEditor}>
      <Flex alignItems="center" justifyContent="space-between" gap="xl">
        <Flex alignItems="center" gap="l">
          <Flex alignItems="baseline" gap="xs">
            <Text
              fontStyle="ml-700"
              className={styles.yourDiscountsLink}
              color="secondary"
            >
              {trans('available-rooms_coupons-summary_title')}
            </Text>
            <CouponsCount />
          </Flex>
          {hasToShowCoupon && (
            <Media laptop desktop>
              <Flex alignItems="center" gap="xs">
                <Icon rotate={!isRTL ? 0 : -180} size="s" icon={SuccessIcon} />
                <Text fontStyle="s-500">{promotionalCoupon?.id}</Text>
              </Flex>
            </Media>
          )}
        </Flex>
        <Icon
          className={styles.icon}
          rotate={!isRTL ? 0 : -180}
          size="s"
          icon={ChevronIcon}
          color="bg-white"
        />
      </Flex>
      <Media mobile tablet>
        {hasToShowCoupon ? (
          <Flex alignItems="center" gap="xs">
            <Icon rotate={!isRTL ? 0 : -180} size="s" icon={SuccessIcon} />
            <Text fontStyle="s-500">{promotionalCoupon?.id}</Text>
            <CouponTag promotionalCoupon={promotionalCoupon} withoutCodeName />
          </Flex>
        ) : (
          <Translate
            i18nKey="new-reservation:available-rooms_coupons-summary_subtitle"
            components={{
              regular: <Text fontStyle="s-500" />,
              bold: (
                <Text fontStyle="s-500" className={styles.addDiscountLink} />
              ),
            }}
          />
        )}
      </Media>
    </div>
  )
}
