import { FC } from 'react'
import styles from './Header.module.scss'
import { Text } from 'src/ui/components/atoms/Text'
import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import { AcceleratorTag } from 'src/ui/views/_components/AcceleratorTag'
import { useAvailabilityAccelerators } from 'src/ui/hooks/useAvailabilityAccelerators'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  room: AvailabilityRoom
  onRoomNameClick: () => void
}

export const Header: FC<Props> = ({ room, onRoomNameClick }) => {
  const { variant, quantity } = useAvailabilityAccelerators(room)

  return (
    <div className={styles.container}>
      <div className={styles.containerInterior}>
        <div className={styles.roomSummary}>
          <ButtonLink onClick={onRoomNameClick}>
            <Text
              as="h2"
              fontStyle={{
                mobile: 'xl-700',
                laptop: '2xl-700',
              }}
              className={styles.roomName}
            >
              {room.name}
            </Text>
          </ButtonLink>
          <AcceleratorTag
            className={styles.acceleratorTag}
            variant={variant}
            quantity={quantity}
          />
        </div>
      </div>
    </div>
  )
}
