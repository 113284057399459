import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { ModalV2 } from 'src/ui/components'
import { Text } from 'src/ui/components'

export const MyBarceloRateInfoModal = createModal(() => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <ModalV2
      size="l"
      title={trans('available-rooms_rate-switch_modal_title')}
      withButtons={false}
    >
      <Text fontStyle="m-300">
        {trans('available-rooms_rate-switch_tooltip')}
      </Text>
    </ModalV2>
  )
})
