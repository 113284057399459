import { createModal } from 'src/ui/hooks/useModal'
import { AsideModal, Button, Loader, Text } from 'src/ui/components'
import styles from './LiteLandingModal.module.scss'
import { UserLevel, UserLevelV2 } from 'src/core/User/domain/User'
import { NotLoggedUserContent } from './NotLoggedUserContent'
import { LoggedUserContent } from './LoggedUserContent'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
interface Props {
  userLevel: UserLevelV2 | UserLevel
  onClose?: () => void
}

export const LiteLandingModal = createModal(({ userLevel, onClose }: Props) => {
  const { trans, isTranslationReady } = useTrans(['myb-lite-landing'])

  function renderLoader() {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        className={styles.loaderContainer}
      >
        <Loader variant="gradient" />
      </Flex>
    )
  }

  function renderContent() {
    if (
      !isDefined(userLevel) ||
      userLevel === UserLevelV2.NotFound ||
      userLevel === UserLevel.NotFound
    ) {
      return <NotLoggedUserContent />
    }
    return <LoggedUserContent userLevel={userLevel} />
  }

  function renderButton() {
    return (
      <div className={styles.buttonWrapper}>
        <Button
          onClick={onClose}
          size={{
            mobile: 'fullWidth',
            tablet: 'large',
          }}
        >
          <Text fontStyle="button" color="light">
            {trans('lite_landing-continue_booking')}
          </Text>
        </Button>
      </div>
    )
  }

  return (
    <AsideModal title="my Barceló" scrollable>
      {isTranslationReady ? (
        <>
          {renderContent()}
          {renderButton()}
        </>
      ) : (
        renderLoader()
      )}
    </AsideModal>
  )
})
