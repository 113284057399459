import { createPortal } from 'react-dom'
import { FC, useEffect, useState } from 'react'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { RoomButtonState, StayButton } from './StayButton'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { classNames } from 'src/ui/utils/classnames'
import styles from './StaysSummary.module.scss'
import { StaySummary } from 'src/ui/views/AvailableRooms/useStaysSummary'
import { Price } from 'src/ui/components/molecules/Price'
import { Price as PriceModel } from 'src/core/Shared/domain/Price.model'
import { ReserveButton } from './ReserveButton'
import { getTotalNights } from 'src/ui/components/molecules/DatePicker/getTotalNights'
import { Translate } from 'src/ui/i18n/Translate'
import { TaxInformation } from '../Room/RateSelector/TaxInformation'
import { usePageWidgetsConfigurator } from 'src/ui/contexts/PageWidgetsProvider'

interface Props {
  staysSummary: StaySummary[]
  currentStayPosition: number
  checkIn: Date | undefined
  checkOut: Date | undefined
  onStaySelected: (stay: number) => void
  reservationInProgress: boolean
  onReserve: () => void
}

export const StaysSummary: FC<Props> = ({
  staysSummary,
  currentStayPosition,
  checkIn,
  checkOut,
  onStaySelected,
  reservationInProgress,
  onReserve,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const [staysSummaryRoot, setStaysSummaryRoot] = useState<HTMLElement | null>(
    null,
  )
  const { setFooterElement } = usePageWidgetsConfigurator()

  useEffect(() => {
    const staysSummaryRoot = document.getElementById('stays-summary-root')
    setStaysSummaryRoot(staysSummaryRoot)
    setFooterElement(staysSummaryRoot)
  }, [setFooterElement])

  const getButtonState = (i: number): RoomButtonState => {
    if (i === currentStayPosition) {
      return 'focused'
    }

    if (isDefined(staysSummary[i].room)) {
      return 'selected'
    }

    return 'unfocused'
  }

  const handleOnStayButtonClick = (position: number) => {
    if (getButtonState(position) === 'focused') {
      return
    }

    onStaySelected(position)
  }

  const allRoomsSelected = staysSummary.every(staysSummary =>
    isDefined(staysSummary.room),
  )
  const someRoomsSelected = staysSummary.some(staysSummary =>
    isDefined(staysSummary.room),
  )

  const showStaysWithScroll = staysSummary.length >= 3

  const calculateButtonMargins = (position: number) => {
    if (position === 0) {
      return styles.firstButton
    }

    return undefined
  }

  const getGrossPrices = () =>
    staysSummary
      .filter(staysSummary => staysSummary.room)
      .map(staysSummary => staysSummary.room?.grossPrice)

  const getNetPrices = () =>
    staysSummary
      .filter(staysSummary => staysSummary.room)
      .map(staysSummary => staysSummary.room?.netPrice)

  const calculatePrice = (
    prices: (PriceModel | undefined)[],
  ): PriceModel | undefined => {
    if (isUndefined(prices[0])) {
      return undefined
    }

    const priceSum = prices.reduce(
      (newValue, currentValue) => newValue + (currentValue?.value ?? 0),
      0,
    )
    //TODO: Sacar el currency de un contexto o algo parecido cuando esté implementado
    const currency = prices[0].currency
    const formattedPrice = Number(priceSum.toFixed(2))

    return {
      value: formattedPrice,
      currency: currency,
    }
  }

  const grossPrice = calculatePrice(getGrossPrices())
  const netPrice = calculatePrice(getNetPrices())
  const totalNights = getTotalNights(checkIn, checkOut)

  function calculateAveragePrices() {
    const averageNetPrice = staysSummary.reduce(
      (averagePrice, roomStay) =>
        averagePrice + roomStay.room!.averageNetPriceNightly.value,
      0,
    )
    const averageGrossPrice = staysSummary.reduce(
      (averagePrice, roomStay) =>
        averagePrice + roomStay.room!.averageGrossPriceNightly.value,
      0,
    )
    return { averageNetPrice, averageGrossPrice }
  }

  const getAverageNightlyPrice = () => {
    const { averageNetPrice, averageGrossPrice } = calculateAveragePrices()

    return {
      grossPrice: {
        value: averageGrossPrice,
        currency: staysSummary[0].room!.averageGrossPriceNightly.currency,
      },
      netPrice: {
        value: averageNetPrice,
        currency: staysSummary[0].room!.averageNetPriceNightly.currency,
      },
    }
  }

  const staysWithScroll = (
    <div className={styles.scrollWrapper}>
      <section className={styles.scrollContainer}>
        {staysSummary.map((staySummary, index) => (
          <StayButton
            key={`${staySummary.room?.name} - ${index}`}
            roomNumber={index + 1}
            staySummary={staySummary}
            className={calculateButtonMargins(index)}
            onClick={() => handleOnStayButtonClick(index)}
            state={getButtonState(index)}
            scrollable
          />
        ))}
      </section>
    </div>
  )

  return staysSummaryRoot
    ? createPortal(
        <section className={styles.container} data-testid="stays-summary">
          <div
            className={classNames(
              styles.wrapper,
              allRoomsSelected && styles.allRoomsSelected,
            )}
          >
            <div className={styles.roomsContainer}>
              <Text
                fontStyle={{ mobile: 'l-700', desktop: 'xl-700' }}
                color="dark"
                className={styles.ellipsisText}
              >
                {allRoomsSelected
                  ? trans('stays-summary_total-selection')
                  : someRoomsSelected
                    ? trans('stays-summary_choose-your-room', {
                        roomNumber: currentStayPosition + 1,
                      })
                    : trans('stays-summary_choose-your-rooms')}
              </Text>
              {showStaysWithScroll ? (
                staysWithScroll
              ) : (
                <section className={styles.staticContainer}>
                  {staysSummary.map((staySummary, index) => (
                    <StayButton
                      key={`${staySummary.room?.name} - ${index}`}
                      roomNumber={index + 1}
                      staySummary={staySummary}
                      state={getButtonState(index)}
                      onClick={() => handleOnStayButtonClick(index)}
                    />
                  ))}
                </section>
              )}
            </div>

            {isDefined(grossPrice) && allRoomsSelected && (
              <Flex
                className={styles.reserveWrapper}
                justifyContent="flex-end"
                direction="row"
                alignItems="flex-start"
                gap="l"
              >
                <div className={styles.priceWrapper}>
                  <Flex
                    alignItems="flex-end"
                    gap="xs"
                    justifyContent="flex-end"
                  >
                    <Price
                      fontStyle={{
                        mobile: 'xl',
                        laptop: '2xl',
                      }}
                      netPrice={getAverageNightlyPrice().netPrice}
                      grossPrice={getAverageNightlyPrice().grossPrice}
                      round="off"
                      dataKey="data-target-summary-total-price"
                      data-testid="stays-summary_nightly-price"
                    />
                  </Flex>
                  <Flex direction="column" alignItems="flex-end">
                    <Text fontStyle="s-500" color="dark">
                      {trans('available-rooms_price-per-night')}
                    </Text>
                    {isDefined(checkIn) &&
                      isDefined(checkOut) &&
                      totalNights > 1 && (
                        <Text fontStyle="s-500" color="dark">
                          <Translate
                            i18nKey="new-reservation:available-rooms_total-stay"
                            components={{
                              price: (
                                <Price
                                  fontStyle="s"
                                  fontWeight="mid"
                                  color="dark"
                                  grossPrice={grossPrice}
                                  netPrice={netPrice}
                                  round="off"
                                  data-testid="stays-summary_total-price"
                                />
                              ),
                            }}
                          />
                        </Text>
                      )}
                    <TaxInformation />
                  </Flex>
                </div>
                <div>
                  <ReserveButton
                    isLoading={reservationInProgress}
                    isDisabled={reservationInProgress}
                    onClick={onReserve}
                  >
                    {trans('available-rooms_member-price-continue')}
                  </ReserveButton>
                </div>
              </Flex>
            )}
          </div>
        </section>,
        staysSummaryRoot,
      )
    : null
}
