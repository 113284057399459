import styles from './CouponsCount.module.scss'
import { Text } from 'src/ui/components'
import { useCoupons } from 'src/ui/contexts/CouponsContext'

export const CouponsCount = () => {
  // TODO: Ahora mismo el numberOfCoupons no tiene en cuenta las campañas ni si está el toggle seleccionado
  // (se fija en si está logueado el usuario. Se tendrá que cambiar en una tarea posterior
  const { numberOfCoupons } = useCoupons()

  return (
    <div className={styles.container}>
      <Text fontStyle="xs-300" color="mid">
        {numberOfCoupons}
      </Text>
    </div>
  )
}
