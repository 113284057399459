import React, { FC, useEffect, useState } from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRoom,
  AvailabilityFlatRate,
  howCouponAppliesInRate,
} from 'src/core/Availability/domain/Availability.model'
import {
  ReservedRoomAndRate,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { OnRateSelected } from '../../AvailableRooms.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MealplansFilter } from './MealplansFilter'
import { TypeRateSelector } from './TypeRateSelector'
import { CurrentRatePrice } from './CurrentRatePrice'
import { Button, Divider } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'
import { Flex } from 'src/ui/styles/objects/Flex'
import { RatePoliciesSelector } from './RatePoliciesSelector'
import { MealplanTag } from './MealplanTag'
import { useMarket } from 'src/ui/contexts/MarketContext'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { classNames } from 'src/ui/utils/classnames'
import {
  buildRatePoliciesFrom,
  getAvailableRatesFrom,
  getRatePoliciesToSelect,
  getRatePoliciesWithRateId,
  hasSomeMealplanWithId,
  RatePolicies,
} from './RateSelector.model'
import { useRateSelector } from 'src/ui/contexts/RateSelectorContext'
import styles from './RateSelector.module.scss'
import { usePageWidgetsConfigurator } from 'src/ui/contexts/PageWidgetsProvider'
import { useMedia } from 'src/ui/hooks/useMedia'

interface Props {
  room: AvailabilityRoom
  reservedRoomAndRate?: ReservedRoomAndRate
  onRateSelected: OnRateSelected
  selectedRoom: SelectedRoom | undefined
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
  defaultMealplan: AvailabilityMealplan
  defaultRatePolicies: RatePolicies
  defaultRate: AvailabilityFlatRate
  rateIdFromURL: string | undefined
}

export const RateSelector: FC<Props> = ({
  room,
  onRateSelected,
  totalNightsFromAvailability,
  isOneNight,
  selectedRoom: previousSelectedRoom,
  reservationInProgress,
  isMultiroom,
  defaultMealplan,
  defaultRatePolicies,
  defaultRate,
  rateIdFromURL,
}) => {
  const { trans } = useTrans(['new-reservation', 'common'])
  const { promotionalCoupon } = useCoupons()
  const {
    selectedGlobalMealplan,
    setSelectedGlobalMealplan,
    selectedGlobalRate,
    setSelectedGlobalRate,
    selectedGlobalRatePolicies,
    setSelectedGlobalRatePolicies,
  } = useRateSelector()
  const { marketPrice } = useMarket()
  const isMarketWithNetPrice = marketPrice?.netPrices

  const [selectedMealplan, setSelectedMealplan] =
    useState<AvailabilityMealplan>(defaultMealplan)
  const [selectedRatePolicy, setSelectedRatePolicy] =
    useState<RatePolicies>(defaultRatePolicies)
  const [selectedRate, setSelectedRate] =
    useState<AvailabilityFlatRate>(defaultRate)

  const hasOnlyOneMealplan = room.mealplans.length === 1
  const selectableRates = getAvailableRatesFrom(selectedRatePolicy)

  const howCouponApplies = () => {
    const baseRate = selectedRatePolicy.rate
    return howCouponAppliesInRate(baseRate, promotionalCoupon)
  }

  useEffect(() => {
    const updateMealplanSelection = () => {
      if (
        isDefined(selectedGlobalMealplan) &&
        hasSomeMealplanWithId(selectedGlobalMealplan.id, room)
      ) {
        const mealplanInCurrentRoom = room.mealplans.find(
          mealplan => mealplan.id === selectedGlobalMealplan.id,
        )
        setSelectedMealplan(mealplanInCurrentRoom!)
      }
    }

    updateMealplanSelection()
  }, [selectedGlobalMealplan, previousSelectedRoom])

  useEffect(() => {
    const updateRatePoliciesSelection = () => {
      const ratePolicies = buildRatePoliciesFrom(selectedMealplan, trans)
      const firstRatePolicy = ratePolicies[0]

      if (isDefined(selectedGlobalRatePolicies)) {
        const ratePoliciesToSelect = getRatePoliciesToSelect(
          ratePolicies,
          selectedGlobalRatePolicies,
          selectedMealplan.id.length,
        )

        if (isUndefined(ratePoliciesToSelect)) {
          setSelectedRatePolicy(firstRatePolicy)
          setSelectedRate(firstRatePolicy.rate)
          return
        }

        setSelectedRatePolicy(ratePoliciesToSelect)
        if (isUndefined(selectedGlobalRate)) {
          setSelectedRate(ratePoliciesToSelect.rate)
        }

        return
      }

      const ratePoliciesToSelect = getRatePoliciesWithRateId(
        ratePolicies,
        rateIdFromURL,
      )

      if (
        isUndefined(rateIdFromURL) ||
        isEmpty(rateIdFromURL) ||
        isUndefined(ratePoliciesToSelect)
      ) {
        setSelectedRatePolicy(firstRatePolicy)
        setSelectedRate(firstRatePolicy.rate)
        return
      }

      setSelectedRatePolicy(ratePoliciesToSelect)
      if (ratePoliciesToSelect.rate.id === rateIdFromURL) {
        setSelectedRate(ratePoliciesToSelect.rate)
        return
      }

      if (isDefined(ratePoliciesToSelect.rate.relatedRate)) {
        setSelectedRate(ratePoliciesToSelect.rate.relatedRate.rate)
      }
    }

    updateRatePoliciesSelection()
  }, [selectedGlobalRatePolicies, selectedMealplan])

  useEffect(() => {
    const changeSelectedRate = (
      ratePoliciesToSelect: RatePolicies | undefined,
    ) => {
      if (
        isUndefined(selectedGlobalRate) ||
        isUndefined(ratePoliciesToSelect)
      ) {
        setSelectedRate(selectedRatePolicy.rate)
        return
      }

      if (
        selectedGlobalRate.isMember ||
        isUndefined(ratePoliciesToSelect.rate.relatedRate)
      ) {
        setSelectedRate(ratePoliciesToSelect.rate)
        return
      }
      if (isDefined(ratePoliciesToSelect.rate.relatedRate)) {
        setSelectedRate(ratePoliciesToSelect.rate.relatedRate.rate)
      }
    }

    const updateRateSelection = () => {
      const ratePolicies = buildRatePoliciesFrom(selectedMealplan, trans)
      const ratePoliciesToSelect = getRatePoliciesToSelect(
        ratePolicies,
        selectedRatePolicy,
        selectedMealplan.id.length,
      )
      changeSelectedRate(ratePoliciesToSelect)
    }

    updateRateSelection()
  }, [selectedGlobalRate, selectedRatePolicy, selectedMealplan])

  const { isMobileOrTablet } = useMedia()
  const { setFooterElement } = usePageWidgetsConfigurator({
    isElementVisible: isMobileOrTablet && !isMultiroom,
  })

  const handleMealplanSelection = (mealplan: AvailabilityMealplan) => {
    setSelectedGlobalMealplan(mealplan)
  }

  const handleRatePoliciesSelection = (ratePolicies: RatePolicies) => {
    setSelectedGlobalRatePolicies(ratePolicies)
  }

  const handleRateSelection = (rate: AvailabilityFlatRate) => {
    setSelectedGlobalRate(rate)
  }

  return (
    <div className={styles.container}>
      {hasOnlyOneMealplan ? (
        <MealplanTag
          mealplan={selectedMealplan}
          className={styles.mealplanFilterContainer}
        />
      ) : (
        <MealplansFilter
          mealplans={room.mealplans}
          selectedMealplan={selectedMealplan}
          onMealplanSelected={handleMealplanSelection}
          className={styles.mealplanFilterContainer}
        />
      )}

      <Media laptop desktop>
        <Divider dir="horizontal" className={styles.divider} />
      </Media>

      <div className={styles.rateSelectorContainer}>
        <RatePoliciesSelector
          ratesPolicies={buildRatePoliciesFrom(selectedMealplan, trans)}
          room={room}
          currentRatePolicies={selectedRatePolicy}
          onRatePoliciesSelected={handleRatePoliciesSelection}
          selectedRate={selectedRate}
        />

        <TypeRateSelector
          room={room}
          selectableRates={selectableRates}
          currentRate={selectedRate}
          onRateSelected={handleRateSelection}
          howCouponApplies={howCouponApplies()}
          totalNightsFromAvailability={totalNightsFromAvailability}
        />
      </div>

      <div
        ref={setFooterElement}
        data-testid={`reserve_container-${room.id}_${selectedRate.id}`}
        className={classNames(
          styles.currentPriceContainer,
          !isMultiroom && styles.isSingleRoom,
        )}
      >
        <CurrentRatePrice
          rate={selectedRate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies()}
        />
        <Button
          data-target-price={
            isMarketWithNetPrice
              ? Math.round(selectedRate.total.netPrice.value)
              : Math.round(selectedRate.total.grossPrice.value)
          }
          data-target-gross-price={Math.round(
            selectedRate.total.grossPrice.value,
          )}
          onClick={() =>
            onRateSelected(
              room.id,
              room.name,
              { id: selectedMealplan.id, name: selectedMealplan.name },
              selectedRate,
            )
          }
          isLoading={reservationInProgress}
          size={{ mobile: 'smallFullWidth', laptop: 'fullWidth' }}
          data-testid={`reserve_button-${room.id}_${selectedRate.id}`}
          data-rate-code={selectedRate.id}
        >
          {trans('available-rooms_select-rate')}
        </Button>
      </div>
    </div>
  )
}
