import { Toggle } from 'src/ui/components/atoms/Toggle'
import styles from './RateSwitch.module.scss'
import { Media } from 'src/ui/styles/objects/Media'
import { Tag, Text } from 'src/ui/components'
import { MyBarceloBenefitsLogoHorizontal } from 'src/ui/views/_components/MyBarceloBenefitsLogo'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FC, useEffect, useRef, useState } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon'
import InfoIcon from 'src/ui/components/atoms/Icon/_icons/interaction/InfoIcon'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import { useMedia } from 'src/ui/hooks/useMedia'
import { useHideHeaderOnScroll } from 'src/ui/hooks/useHideHeaderOnScroll'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { useRateSelectorV2 } from 'src/ui/contexts/RateSelectorContextV2'
import { useModal } from 'src/ui/hooks/useModal'
import { MyBarceloRateInfoModal } from 'src/ui/views/AvailableRooms/RateSwitch/MyBarceloRateInfoModal'

interface Props {
  onChange: (value: boolean) => void
}

export const RateSwitch: FC<Props> = ({ onChange }) => {
  const { trans } = useTrans(['common', 'new-reservation'])
  const { isMyBarceloToggleSelected } = useRateSelectorV2()
  const stickyRef = useRef<HTMLDivElement>(null)
  const sentinelTopRef = useRef<HTMLDivElement>(null)
  const sentinelBottomRef = useRef<HTMLDivElement>(null)
  const [isTopVisible, setIsTopVisible] = useState(true)
  const [isBottomVisible, setIsBottomVisible] = useState(true)
  const { isMobileOrTablet } = useMedia()
  const isHidden = useHideHeaderOnScroll()
  const { isCouponRibbonVisible } = useCoupons()
  const { showModal: showMyBarceloRateModal } = useModal(MyBarceloRateInfoModal)

  useEffect(() => {
    const observerOptions = { threshold: 0 }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.target === sentinelTopRef.current) {
        setIsTopVisible(entry.isIntersecting)
      } else if (entry.target === sentinelBottomRef.current) {
        setIsBottomVisible(entry.isIntersecting)
      }
    }, observerOptions)

    if (sentinelTopRef.current) observer.observe(sentinelTopRef.current)
    if (sentinelBottomRef.current) observer.observe(sentinelBottomRef.current)

    return () => observer.disconnect()
  }, [])

  const isSticky = !isTopVisible && !isBottomVisible

  const getStickyClassNames = () => {
    if (!isSticky) {
      return []
    }

    const stickyClasses = [styles.sticky]

    if (isCouponRibbonVisible && (isHidden || isMobileOrTablet)) {
      stickyClasses.push(styles.sticky_withOnlyRibbon)
    }

    if (!isCouponRibbonVisible && !isHidden) {
      stickyClasses.push(styles.sticky_withOnlyHeader)
    }

    if (isCouponRibbonVisible && !isHidden) {
      stickyClasses.push(styles.sticky_withRibbonAndHeader)
    }

    return stickyClasses
  }

  const InfoTagAndTooltip = (
    <Flex gap="xs" alignItems="flex-end">
      <Tag className={styles.bestPriceTag}>
        <Text color="light" fontStyle="xs-700">
          {trans('fastbooking_best-price-guaranteed')}
        </Text>
      </Tag>
      {isMobileOrTablet ? (
        <button
          onClick={async () => {
            await showMyBarceloRateModal()
          }}
        >
          <Icon
            className="cursor-pointer"
            size="l"
            icon={InfoIcon}
            ariaLabel={trans(
              'new-reservation:available-rooms_rate-switch_tooltip',
            )}
          />
        </button>
      ) : (
        <Tooltip
          triggerElement={
            <Icon
              className="cursor-pointer"
              size="l"
              icon={InfoIcon}
              ariaLabel={trans(
                'new-reservation:available-rooms_rate-switch_tooltip',
              )}
            />
          }
          tooltipContent={trans(
            'new-reservation:available-rooms_rate-switch_tooltip',
          )}
          tooltipWrapperClassName={styles.tooltipContent}
        />
      )}
    </Flex>
  )

  const renderToggle = () => {
    const ToggleButton = (
      <Toggle
        data-testid="rate-toggle"
        isChecked={isMyBarceloToggleSelected}
        ariaLabel={trans(
          isMyBarceloToggleSelected
            ? 'new-reservation:available-rooms_rate-switch_myBarcelo'
            : 'new-reservation:available-rooms_rate-switch_standard',
        )}
        onChange={onChange}
      />
    )

    if (isMobileOrTablet) {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap="xs" alignItems="flex-end">
            <Text fontStyle="m-700">Tarifa</Text>
            <MyBarceloBenefitsLogoHorizontal />
          </Flex>
          {ToggleButton}
        </Flex>
      )
    }

    return (
      <Flex alignItems="flex-end" gap="xs">
        {ToggleButton}
        <Text fontStyle="m-700">
          {trans('new-reservation:available-rooms_rate-switch_rate')}
        </Text>
        <MyBarceloBenefitsLogoHorizontal />
        {!isSticky && isMyBarceloToggleSelected ? InfoTagAndTooltip : <></>}
      </Flex>
    )
  }

  return (
    <>
      <div ref={sentinelTopRef} style={{ height: '1px' }} />
      <div
        className={classNames(
          styles.container,
          ...getStickyClassNames(),
          !isMyBarceloToggleSelected && styles.container_standardSelected,
        )}
        ref={stickyRef}
      >
        <Media mobile tablet>
          {!isSticky ? (
            <Text as="p" fontStyle="l-700" className="mb-m">
              {trans('new-reservation:available-rooms_rate-switch_title')}
            </Text>
          ) : (
            <></>
          )}
        </Media>
        {renderToggle()}
        {!isSticky ? (
          <>
            <Text as="p" fontStyle="s-500" className="mt-xs">
              {trans(
                isMyBarceloToggleSelected
                  ? 'new-reservation:available-rooms_rate-switch_description'
                  : 'new-reservation:available-rooms_rate-switch_description_estandar',
              )}
            </Text>
            {isMyBarceloToggleSelected && (
              <Media mobile tablet>
                {InfoTagAndTooltip}
              </Media>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div ref={sentinelBottomRef} style={{ height: '1px' }} />
    </>
  )
}
