import {
  AvailabilityRate,
  AvailabilityFlatRate,
  AvailabilityMealplan,
  AvailabilityMealplanId,
  AvailabilityRoom,
  getRateFromRoom,
} from 'src/core/Availability/domain/Availability.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { getGuaranteeTitle } from 'src/ui/i18n/getGuaranteeTitle'
import { getCancellationTitle } from 'src/ui/i18n/getCancellationTitle'
import { Translator } from 'src/ui/hooks/useTrans'
import { SelectedRoom } from 'src/core/Shared/domain/Reservation.model'

export interface SelectableRates {
  memberRate: AvailabilityRate | undefined
  standardRate: AvailabilityFlatRate | AvailabilityRate | undefined
}
export interface RatePolicies {
  id: string
  rate: AvailabilityRate
  name: string
  guarantee: string
  cancellation: string
  isCancellationHighlighted: boolean
}

export function getRatePoliciesWithRateId(
  ratePolicies: RatePolicies[],
  rateId?: string,
) {
  return ratePolicies.find(
    ratePolicy =>
      ratePolicy.rate.id === rateId ||
      ratePolicy.rate.relatedRate?.rate.id === rateId,
  )
}

export function getAvailableRatesFrom(
  ratePolicies: RatePolicies,
): SelectableRates {
  if (ratePolicies.rate.isMember) {
    return {
      memberRate: ratePolicies.rate,
      standardRate: ratePolicies.rate.relatedRate?.rate,
    }
  } else {
    return {
      memberRate: undefined,
      standardRate: ratePolicies.rate,
    }
  }
}

export function memberRateHasTag(memberRate: AvailabilityRate | undefined) {
  return isDefined(memberRate) && memberRate.isMember
}

const mapRateToRatePolicies = (
  rate: AvailabilityRate,
  trans: Translator,
): RatePolicies => {
  const guaranteeTitle = getGuaranteeTitle(
    rate.guarantee,
    'before-reservation',
    trans,
  )

  const cancellationTitle = getCancellationTitle(
    rate.cancellation,
    trans,
    rate.guarantee,
  )

  const isCancellationHighlighted =
    rate.cancellation.cancellationType === 'free' &&
    rate.guarantee.type.key !== 'partial-percentage'

  return {
    id: rate.id,
    rate: rate,
    guarantee: guaranteeTitle,
    cancellation: cancellationTitle,
    isCancellationHighlighted,
    name: `G: ${rate.guarantee.type.key} - C: {applies: ${rate.cancellation.applies}, flexible: ${rate.cancellation.flexible}, penalty: ${rate.cancellation.relative.penalty.value} ${rate.cancellation.relative.penalty.type}}`,
  }
}

export const buildRatePoliciesFrom = (
  mealplan: AvailabilityMealplan,
  trans: Translator,
): RatePolicies[] => {
  return mealplan.rates.map(rate => mapRateToRatePolicies(rate, trans))
}

const findInMealplansById = (
  mealplanId: string | undefined,
  room: AvailabilityRoom,
) => room.mealplans.find(mp => mp.id === mealplanId)
export const hasSomeMealplanWithId = (
  mealplanId: string,
  room: AvailabilityRoom,
) => room.mealplans.some(mp => mp.id === mealplanId)

export const getDefaultMealplan = (
  previousSelectedRoom: SelectedRoom | undefined,
  selectedGlobalMealplan: AvailabilityMealplan | undefined,
  mealplanFromURL: AvailabilityMealplanId | undefined,
  room: AvailabilityRoom,
): AvailabilityMealplan => {
  const currentMealplanInCart = findInMealplansById(
    previousSelectedRoom?.mealplanId,
    room,
  )

  if (isDefined(currentMealplanInCart)) {
    return currentMealplanInCart
  }

  if (
    isDefined(selectedGlobalMealplan) &&
    hasSomeMealplanWithId(selectedGlobalMealplan.id, room)
  ) {
    return selectedGlobalMealplan
  }

  if (isDefined(mealplanFromURL)) {
    const mealplan = findInMealplansById(mealplanFromURL, room)

    if (isDefined(mealplan)) {
      return mealplan
    }
  }

  return room.mealplans[0]
}

export const getDefaultRatePolicies = (
  defaultMealplan: AvailabilityMealplan,
  ratePolicies: RatePolicies[],
  previousSelectedRoom: SelectedRoom | undefined,
  rateIdFromURL: string | undefined,
  trans: Translator,
): RatePolicies => {
  const currentRatePolicy = getRatePoliciesWithRateId(
    ratePolicies,
    previousSelectedRoom?.id ?? rateIdFromURL,
  )
  return currentRatePolicy || buildRatePoliciesFrom(defaultMealplan, trans)[0]
}

export const getDefaultRate = (
  defaultRatePolicies: RatePolicies,
  previousSelectedRoom: SelectedRoom | undefined,
  room: AvailabilityRoom,
  rateIdFromURL: string | undefined,
): AvailabilityFlatRate => {
  const currentRate = getRateFromRoom(
    room,
    isDefined(previousSelectedRoom) ? previousSelectedRoom.id : rateIdFromURL,
  )

  return currentRate ?? defaultRatePolicies.rate
}

export const getRatePoliciesToSelect = (
  ratePolicies: RatePolicies[],
  ratePolicyFilter: RatePolicies,
  mealplanIdLength: number,
) => {
  const ratePolicyFilterId = ratePolicyFilter.id.slice(0, -mealplanIdLength)
  return ratePolicies.find(
    ratePolicy =>
      ratePolicy.id.slice(0, -mealplanIdLength) === ratePolicyFilterId,
  )
}
