import React, { FC, useEffect, useState } from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRoom,
  AvailabilityFlatRate,
  howCouponAppliesInRate,
} from 'src/core/Availability/domain/Availability.model'
import {
  ReservedRoomAndRate,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { OnRateSelected } from '../../AvailableRooms.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MealplansFilter } from './MealplansFilter'
import { TypeRateSelectorV2 } from './TypeRateSelector'
import { CurrentRatePrice } from './CurrentRatePrice'
import { Button, Divider } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'
import { Flex } from 'src/ui/styles/objects/Flex'
import { MealplanTag } from './MealplanTag'
import { useMarket } from 'src/ui/contexts/MarketContext'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { classNames } from 'src/ui/utils/classnames'
import {
  buildRatePoliciesFrom,
  getAvailableRatesFrom,
  getRatePoliciesToSelect,
  getRatePoliciesWithRateId,
  hasSomeMealplanWithId,
  RatePolicies,
} from './RateSelector.model'
import { useRateSelector } from 'src/ui/contexts/RateSelectorContext'
import styles from './RateSelector.module.scss'
import { useRateSelectorV2 } from 'src/ui/contexts/RateSelectorContextV2'
import { RatePoliciesSelectorV2 } from 'src/ui/views/AvailableRooms/Room/RateSelector/RatePoliciesSelectorV2'

interface Props {
  room: AvailabilityRoom
  reservedRoomAndRate?: ReservedRoomAndRate
  onRateSelected: OnRateSelected
  selectedRoom: SelectedRoom | undefined
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
  defaultMealplan: AvailabilityMealplan
  defaultRatePolicies: RatePolicies
  defaultRate: AvailabilityFlatRate
  rateIdFromURL: string | undefined
}

export const RateSelectorV2: FC<Props> = ({
  room,
  onRateSelected,
  totalNightsFromAvailability,
  isOneNight,
  selectedRoom: previousSelectedRoom,
  reservationInProgress,
  isMultiroom,
  defaultMealplan,
  defaultRatePolicies,
  defaultRate,
  rateIdFromURL,
}) => {
  const { trans } = useTrans(['new-reservation', 'common'])
  const { promotionalCoupon } = useCoupons()
  const {
    selectedGlobalMealplan,
    setSelectedGlobalMealplan,
    selectedGlobalRatePolicies,
    setSelectedGlobalRatePolicies,
    isMyBarceloToggleSelected,
  } = useRateSelectorV2()
  const { marketPrice } = useMarket()
  const isMarketWithNetPrice = marketPrice?.netPrices
  const [selectedMealplan, setSelectedMealplan] =
    useState<AvailabilityMealplan>(defaultMealplan)
  const [selectedRatePolicy, setSelectedRatePolicy] =
    useState<RatePolicies>(defaultRatePolicies)
  const [selectedRate, setSelectedRate] =
    useState<AvailabilityFlatRate>(defaultRate)

  const hasOnlyOneMealplan = room.mealplans.length === 1
  const selectableRates = getAvailableRatesFrom(selectedRatePolicy)

  const howCouponApplies = () => {
    const baseRate = selectedRatePolicy.rate
    return howCouponAppliesInRate(baseRate, promotionalCoupon)
  }

  useEffect(() => {
    const updateMealplanSelection = () => {
      const getMealplanForRoom = (mealplanId: string) =>
        room.mealplans.find(mealplan => mealplan.id === mealplanId)

      const getDefaultMealplan = () => room.mealplans[0]

      const targetMealplanId = selectedGlobalMealplan?.id ?? selectedMealplan.id

      const mealplanInCurrentRoom = hasSomeMealplanWithId(
        targetMealplanId,
        room,
      )
        ? getMealplanForRoom(targetMealplanId)!
        : getDefaultMealplan()

      setSelectedMealplan(mealplanInCurrentRoom)
    }

    updateMealplanSelection()
  }, [isMyBarceloToggleSelected, selectedGlobalMealplan, previousSelectedRoom])

  useEffect(() => {
    const updateRatePoliciesSelection = () => {
      const ratePolicies = buildRatePoliciesFrom(selectedMealplan, trans)
      const firstRatePolicy = ratePolicies[0]

      if (isDefined(selectedGlobalRatePolicies)) {
        const ratePoliciesToSelect = getRatePoliciesToSelect(
          ratePolicies,
          selectedGlobalRatePolicies,
          selectedMealplan.id.length,
        )

        if (isUndefined(ratePoliciesToSelect)) {
          setSelectedRatePolicy(firstRatePolicy)
          return
        }

        setSelectedRatePolicy(ratePoliciesToSelect)

        return
      }

      const ratePoliciesToSelect = getRatePoliciesWithRateId(
        ratePolicies,
        rateIdFromURL,
      )

      if (
        isUndefined(rateIdFromURL) ||
        isEmpty(rateIdFromURL) ||
        isUndefined(ratePoliciesToSelect)
      ) {
        setSelectedRatePolicy(firstRatePolicy)
        return
      }

      setSelectedRatePolicy(ratePoliciesToSelect)
      if (ratePoliciesToSelect.rate.id === rateIdFromURL) {
        setSelectedRate(ratePoliciesToSelect.rate)
        return
      }
    }

    updateRatePoliciesSelection()
  }, [selectedGlobalRatePolicies, selectedMealplan])

  useEffect(() => {
    setSelectedRate(selectedRatePolicy.rate)
  }, [selectedRatePolicy, selectedMealplan])

  const handleMealplanSelection = (mealplan: AvailabilityMealplan) => {
    setSelectedGlobalMealplan(mealplan)
  }

  const handleRatePoliciesSelection = (ratePolicies: RatePolicies) => {
    setSelectedGlobalRatePolicies(ratePolicies)
  }

  return (
    <div className={styles.container}>
      {hasOnlyOneMealplan ? (
        <MealplanTag
          mealplan={selectedMealplan}
          className={styles.mealplanFilterContainer}
        />
      ) : (
        <MealplansFilter
          mealplans={room.mealplans}
          selectedMealplan={selectedMealplan}
          onMealplanSelected={handleMealplanSelection}
          className={styles.mealplanFilterContainer}
        />
      )}

      <Media laptop desktop>
        <Divider dir="horizontal" className={styles.divider} />
      </Media>

      <div className={styles.rateSelectorContainer}>
        <RatePoliciesSelectorV2
          ratesPolicies={buildRatePoliciesFrom(selectedMealplan, trans)}
          room={room}
          currentRatePolicies={selectedRatePolicy}
          onRatePoliciesSelected={handleRatePoliciesSelection}
          selectedRate={selectedRate}
        />

        <TypeRateSelectorV2
          room={room}
          selectableRates={selectableRates}
          currentRate={selectedRate}
          howCouponApplies={howCouponApplies()}
          totalNightsFromAvailability={totalNightsFromAvailability}
        />
      </div>

      <Flex
        direction="column"
        alignItems="flex-end"
        data-testid={`reserve_container-${room.id}_${selectedRate.id}`}
        className={classNames(
          styles.currentPriceContainer,
          !isMultiroom && styles.isSingleRoom,
        )}
      >
        <CurrentRatePrice
          rate={selectedRate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies()}
        />
        <Button
          data-target-price={
            isMarketWithNetPrice
              ? Math.round(selectedRate.total.netPrice.value)
              : Math.round(selectedRate.total.grossPrice.value)
          }
          data-target-gross-price={Math.round(
            selectedRate.total.grossPrice.value,
          )}
          onClick={() =>
            onRateSelected(
              room.id,
              room.name,
              { id: selectedMealplan.id, name: selectedMealplan.name },
              selectedRate,
            )
          }
          isLoading={reservationInProgress}
          size={{ mobile: 'smallFullWidth', laptop: 'fullWidth' }}
          data-testid={`reserve_button-${room.id}_${selectedRate.id}`}
          data-rate-code={selectedRate.id}
        >
          {trans('available-rooms_select-rate')}
        </Button>
      </Flex>
    </div>
  )
}
