import { FC, useEffect, useState } from 'react'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import styles from './Toggle.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  isChecked?: boolean
  onChange?: (checked: boolean) => void
  ariaLabel?: string
  'data-testid'?: string
}

export const Toggle: FC<Props> = ({
  isChecked = false,
  ariaLabel,
  onChange,
  'data-testid': testId,
}) => {
  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    if (isDefined(onChange)) {
      onChange(newChecked)
    }
  }

  return (
    <button
      data-testid={testId}
      className={classNames(styles.toggle, checked ? styles.checked : '')}
      aria-label={ariaLabel}
      onClick={handleChange}
    >
      <span className={styles.toggleCircle} />
    </button>
  )
}
