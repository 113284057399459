import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { Promotion } from 'src/core/Hotel/domain/Promotion.model'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const getHotelPromotions =
  ({ hotelRepository }: Dependencies) =>
  async (
    hotelId: string,
    checkIn: string,
    checkOut: string,
  ): Promise<Promotion[] | undefined> => {
    if (!Time.isValid(checkIn) || !Time.isValid(checkOut)) {
      return undefined
    }

    const alerts = await hotelRepository.hotelPromotions(
      hotelId,
      checkIn,
      checkOut,
    )

    if (isUndefined(alerts) || isEmpty(alerts)) {
      return undefined
    }

    return alerts
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
