import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRate,
} from 'src/core/Availability/domain/Availability.model'
import { RatePolicies } from 'src/ui/views/AvailableRooms/Room/RateSelector/RateSelector.model'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useCart } from 'src/ui/contexts/CartContext'
import { hasSomeMemberRate } from 'src/core/Cart/domain/Cart.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

const defaultState = {
  selectedGlobalMealplan: undefined,
  setSelectedGlobalMealplan: () => {},
  selectedGlobalRatePolicies: undefined,
  setSelectedGlobalRatePolicies: () => {},
  isMyBarceloToggleSelected: true,
  setIsMyBarceloToggleSelected: () => {},
}

export const RateSelectorContextV2 = createContext<{
  selectedGlobalMealplan: AvailabilityMealplan | undefined
  setSelectedGlobalMealplan: (mealplan: AvailabilityMealplan) => void
  selectedGlobalRatePolicies: RatePolicies | undefined
  setSelectedGlobalRatePolicies: (ratePolicies: RatePolicies) => void
  isMyBarceloToggleSelected: boolean
  setIsMyBarceloToggleSelected: (isMyBarceloToggleSelected: boolean) => void
}>(defaultState)

interface Props {
  children: ReactNode
}

export const RateSelectorProviderV2: FC<Props> = ({ children }) => {
  const { cart } = useCart()

  const [selectedGlobalMealplan, setSelectedGlobalMealplan] = useState<
    AvailabilityMealplan | undefined
  >()
  const [selectedGlobalRatePolicies, setSelectedGlobalRatePolicies] = useState<
    RatePolicies | undefined
  >()
  const [isMyBarceloToggleSelected, setIsMyBarceloToggleSelected] =
    useState(true)

  const resetRateSelector = useCallback(() => {
    setSelectedGlobalMealplan(undefined)
    setSelectedGlobalRatePolicies(undefined)
  }, [])

  useEffect(() => {
    const eventsManager = container.resolve('eventsManager')
    const unsubscribe = eventsManager.on(
      CustomEvent.RESET_PRE_RESERVE,
      resetRateSelector,
    )

    return () => {
      resetRateSelector()
      unsubscribe()
    }
  }, [resetRateSelector])

  useEffect(() => {
    if (isDefined(cart)) {
      setIsMyBarceloToggleSelected(hasSomeMemberRate(cart))
    }
  }, [cart])

  return (
    <RateSelectorContextV2.Provider
      value={{
        selectedGlobalMealplan,
        setSelectedGlobalMealplan,
        selectedGlobalRatePolicies,
        setSelectedGlobalRatePolicies,
        isMyBarceloToggleSelected,
        setIsMyBarceloToggleSelected,
      }}
    >
      {children}
    </RateSelectorContextV2.Provider>
  )
}

export const useRateSelectorV2 = () => useContext(RateSelectorContextV2)
