import {
  Availability,
  AvailabilityCriteria,
} from 'src/core/Availability/domain/Availability.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { AvailabilityRepository } from 'src/core/Availability/domain/Availability.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { Campaign } from 'src/core/Hotel/domain/Campaign.model'

export const getAvailabilityByGroupCoupon =
  ({ availabilityRepository, analytics, hotelRepository }: Dependencies) =>
  async (
    request: AvailabilityCriteria | undefined,
    language: string,
    setNoBarceloApplicationExperience?: () => void,
  ): Promise<{ availability: Availability } | undefined> => {
    if (isUndefined(request)) {
      return undefined
    }

    try {
      const [availability, campaign] = await Promise.all([
        availabilityRepository.all(request),
        getCampaignForCoupon(request, language),
      ])
      analytics.requests.availability(availability)

      if (
        !campaign?.isBarceloExperienceActive &&
        setNoBarceloApplicationExperience
      ) {
        setNoBarceloApplicationExperience()
      }

      return { availability }
    } catch (error) {
      if (
        error instanceof AvailabilityError &&
        error.type === AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR
      ) {
        const availability = await availabilityRepository.all({
          ...request,
          groupCode: undefined,
        })
        analytics.requests.availability(availability)

        return { availability }
      }

      throw error
    }

    function getCampaignForCoupon(
      request: AvailabilityCriteria,
      language: string,
    ): Promise<Campaign | undefined> {
      if (!request.groupCode) {
        return Promise.resolve(undefined)
      }

      try {
        return hotelRepository.getCampaignForCoupon({
          marketprice: request.market,
          language,
          hotelId: request.hotel,
          promotionalCoupon: request.groupCode,
        })
      } catch (error) {
        return Promise.resolve(undefined)
      }
    }
  }

interface Dependencies {
  availabilityRepository: AvailabilityRepository
  hotelRepository: HotelRepository
  analytics: Analytics
}
